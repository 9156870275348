import React from "react";
import Footer from "./components/footer";
import Navigation from "./components/nav";
import SeeTestimonies from "./components/seeTestimonies";

const Testimonies = () => {

    return (
        <>
            <Navigation />
            <SeeTestimonies />
            <Footer />
        </>
    )
}

export default Testimonies;