import React,{useState, useEffect} from "react";
import Navigation from "./components/nav";
import HomeBody from "./components/homebody";
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button"
import Footer from "./components/footer";
import YouTube from "@mui/icons-material/YouTube"
import Container from '@mui/material/Container';
import HomePageEvents from "./components/homePageEvents";
import { Link } from 'react-router-dom';
import axios from "axios";
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import PullGallery from "./components/pullGallery";

const Gallery = () => {


    return (
        <>
            <Navigation />

            <PullGallery />

            <Footer />
        </>
    )
}

export default Gallery;