import React from "react";
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import vivCircle from '../../../src/images/circleVivian.png';
import vivChoir from '../../../src/images/vivChoir.jpg';
import vivPeople from '../../../src/images/vivPeople.JPG';
import vivian from '../../../src/images/vivian.jpg';
import vivWomen from '../../../src/images/vivWomen.jpg';
import vivPrists from '../../../src/images/vivPrists.jpg';

function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

const AboutBody = () => {

    const ItemOne = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(4),
        textAlign: 'left',
        color: theme.palette.text.secondary,
      }));

      const ItemTwo = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(0),
        textAlign: 'left',
        color: theme.palette.text.secondary,
      }));

    return (
        <>
           <Box sx={{display: { xs: 'none', md: 'flex' }}}>
                <div class='ripple-background'>
                    <Box sx={{flexGrow: 1, padding: 2}}>
                    <Typography variant="h1" color="primary" sx={{fontWeight : 700}}>
                                            About Vivian Ogu
                                        </Typography>
                        <Grid container spacing={2}>
                                
                                <Grid item xs={4}>
                                
                                <ImageList
                                sx={{ width: "100%", height: 500 }}
                                variant="quilted"
                                cols={4}
                                rowHeight={121}
                                >
                                {itemData.map((item) => (
                                    <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                                    <img
                                        {...srcset(item.img, 121, item.rows, item.cols)}
                                        alt={item.title}
                                        loading="lazy"
                                    />
                                    </ImageListItem>
                                ))}
                                </ImageList>

                                <img 
                        src={require('../../../src/images/vivPrists.jpg')}
                        style={{width : "100%", height : "300px", marginRight : "10px"}}
                    />
                                </Grid>

                                <Grid item xs={8}>
                                    <ItemOne>
                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                        <p>
                                        Vivian Uchechi Ogu was a 14-year-old girl who lived an exemplary holy life through her missionary works
                                        and died a heroic death by opting to be killed rather than being sexually defiled.
                                        </p>
                                        </Typography>

                                        <Typography variant="h6" color="primary" sx={{fontWeight : 400}}>
                                            Biological Background
                                        </Typography>

                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                        <p>
                                        Vivian was born in Benin City on the 1st of April, 1995 to the catholic family of Mr. and Mrs. Peter Ogu of
                                        Enyiogugu of Aboh-Mbaise Local government area of Imo State. She was the second out of four children.
                                        </p>
                                        </Typography>

                                        <Typography variant="h6" color="primary" sx={{fontWeight : 400}}>
                                            Childhood
                                        </Typography>
                                        
                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                            <p>
                                            Vivian attended Air Force Nursery and Primary School where she distinguished herself as the most
                                            intelligent pupil in her class. She was a parishioner of St. Paul Catholic Church Airport Road Benin City., a
                                            member of the Catholic Charismatic Renewal, the choir mistress of the children&#39;s choir, and the
                                            president of the Holy Childhood Association in the parish, where she made a remarkable impact both at
                                            the parish and Archdiocesan level.
                                            </p>
                                        </Typography>
                                        
                                        <Typography variant="h6" color="primary" sx={{fontWeight : 400}}>
                                            Works
                                        </Typography>
                                        
                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                            <p>
                                            As the president of the holy childhood association, at the age of nine years, she mobilized funds to pay
                                            the bill of many poor children in the Central hospital, Benin City. She organized several visits to
                                            orphanage homes where she touched the lives of so many poor orphans.
                                            As a renowned preacher, she inspired and mentored so many children and even youths, especially in her
                                            parish and her School in following the path of holiness and defending the dignity of virginity, following
                                            the footsteps of St. Maria Goretti, her favourite saint.
                                            </p>

                                            <p>
                                            At the age of thirteen, she already embarked on the development of her commentary on the Gospel of
                                            Matthew. She had reached the 16th chapter of that Gospel before her death.
                                            </p>

                                            <p>
                                            She was in Senior Secondary II in Greater Tomorrow Secondary School and the assistant prayer leader in
                                             her school before her death.    
                                            </p>
                                        </Typography>

                                    </ItemOne>
                                </Grid>
                        </Grid>
                        <Box sx={{flexGrow: 1, padding: 1, marginTop : 5}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}> 
                                <ItemOne>
                                        <Typography variant="h6" color="primary" sx={{fontWeight : 400}}>
                                            Death
                                        </Typography>
                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                            <p>
                                            On Sunday, the 15th of November, 2009, after preaching about purity, virginity, and martyrdom to her
                                        fellow children in the church in the morning, the opportunity came for her to put into practice what she
                                        preached all her life.
                                            </p>
                                            <p>
                                            While at home that evening, armed robbers came to rob her family and afterward took her to a nearby
                                        bush and attempted to rape her. She vehemently refused and opted to be shot instead and indeed she
                                        was martyred.
                                            </p>
                                        </Typography>
                                        <Typography variant="h6" color="primary" sx={{fontWeight : 400}}>
                                        Government Honours
                                        </Typography>
                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                            <p>
                                            Having learned the news of her heroic death, the government of Edo State granted the land where
                                        Vivian Ogu was martyred to the Catholic Archdiocese of Benin City. Two years later, the local
                                        government council of Ikpoba Okha officially named the street on which she was killed “Vivian Ogu”. On
                                        the 17th of September 2019, the Edo State government launched the Vivian Ogu Sexual Assault Referral
                                        Centre which provides services and support to survivors of sexual assault in Edo State.
                                            </p>
                                        </Typography>
                                </ItemOne>
                            </Grid>
                            <Grid item xs={6}> 
                                <ItemOne>
                                <Typography variant="h6" color="primary" sx={{fontWeight : 400}}>
                                Catholic Honours
                                        </Typography>
                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                        <p>
                                        Since the death of Vivian Ogu, the Catholic Archdiocese of Benin City has consistently celebrated
                                        Memorial Day for Vivian Ogu on the 15th of November every year. The Vivian Ogu Movement was
                                        inaugurated in 2014 by the Catholic Archbishop of Benin, Most Rev. Dr. Augustine Obiora Akubeze with
                                        the task of:
                                        </p>

                                        <List>
                                            <ListItem>
                                            Promoting the story of her exemplary life and heroic death so that many more may strive towards
                                        her heroic virtues and seek her intercession.
                                            </ListItem>
                                            <ListItem>
                                            Preserving and developing the land where she was killed.
                                            </ListItem>

                                        </List>

                                        <p>
                                        To celebrate the extraordinary missionary month in October 2019, the Holy Father Pope Francis asked
                                        for the biography of possible saint figures, canonized or not, to be used as heroic missionary examples
                                        for the whole world. Vivian Ogu was one of the twenty-five saint figures selected from the entire
                                        countries of the world.
                                        </p>

                                        <p>
                                        Consequently, on the 26th of October 2019, the entire Catholic Church in Nigeria gathered at Vivian Ogu
                                        Animation Centre (VOMAC) for the national celebration of the extraordinary missionary month of
                                        October. The Holy Father Pope Francis was represented by the president of the Pontifical Mission
                                        Society, Archbishop Giampietro Dal Toso.
                                        </p>
                                        </Typography>
                                </ItemOne>
                            </Grid>
                        </Grid>
                        </Box>
                    </Box>
                    <div class='circle xxlarge shade1'></div>
                    <div class='circle xlarge shade2'></div>
                    <div class='circle large shade3'></div>
                    <div class='circle mediun shade4'></div>
                    <div class='circle small shade5'></div>
                    </div>
           </Box>

           <Box sx={{display: { xs: 'flex', md: 'none' }}}>
                <div class='ripple-background'>
                    <Box sx={{flexGrow: 1, padding: 2}}>
                      <Grid>
                          <Grid item xs={12}>
                              <Typography variant="h3" color="primary" sx={{fontWeight : 700}}>
                                  About Vivian Ogu
                              </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Grid container spacing={2}>
                                
                                <Grid item xs={12}>
                                
                                <ImageList
                                sx={{ width: "100%", height: 500 }}
                                variant="quilted"
                                cols={4}
                                rowHeight={121}
                                >
                                {itemData.map((item) => (
                                    <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                                    <img
                                        {...srcset(item.img, 121, item.rows, item.cols)}
                                        alt={item.title}
                                        loading="lazy"
                                    />
                                    </ImageListItem>
                                ))}
                                </ImageList>

                                <img 
                        src={require('../../../src/images/vivPrists.jpg')}
                        style={{width : "100%", height : "300px", marginRight : "10px"}}
                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <ItemOne>
                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                        <p>
                                        Vivian Uchechi Ogu was a 14-year-old girl who lived an exemplary holy life through her missionary works
                                        and died a heroic death by opting to be killed rather than being sexually defiled.
                                        </p>
                                        </Typography>

                                        <Typography variant="h6" color="primary" sx={{fontWeight : 400}}>
                                            Biological Background
                                        </Typography>

                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                        <p>
                                        Vivian was born in Benin City on the 1st of April, 1995 to the catholic family of Mr. and Mrs. Peter Ogu of
                                        Enyiogugu of Aboh-Mbaise Local government area of Imo State. She was the second out of four children.
                                        </p>
                                        </Typography>

                                        <Typography variant="h6" color="primary" sx={{fontWeight : 400}}>
                                            Childhood
                                        </Typography>
                                        
                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                            <p>
                                            Vivian attended Air Force Nursery and Primary School where she distinguished herself as the most
                                            intelligent pupil in her class. She was a parishioner of St. Paul Catholic Church Airport Road Benin City., a
                                            member of the Catholic Charismatic Renewal, the choir mistress of the children&#39;s choir, and the
                                            president of the Holy Childhood Association in the parish, where she made a remarkable impact both at
                                            the parish and Archdiocesan level.
                                            </p>
                                        </Typography>
                                        
                                        <Typography variant="h6" color="primary" sx={{fontWeight : 400}}>
                                            Works
                                        </Typography>
                                        
                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                            <p>
                                            As the president of the holy childhood association, at the age of nine years, she mobilized funds to pay
                                            the bill of many poor children in the Central hospital, Benin City. She organized several visits to
                                            orphanage homes where she touched the lives of so many poor orphans.
                                            As a renowned preacher, she inspired and mentored so many children and even youths, especially in her
                                            parish and her School in following the path of holiness and defending the dignity of virginity, following
                                            the footsteps of St. Maria Goretti, her favourite saint.
                                            </p>

                                            <p>
                                            At the age of thirteen, she already embarked on the development of her commentary on the Gospel of
                                            Matthew. She had reached the 16th chapter of that Gospel before her death.
                                            </p>

                                            <p>
                                            She was in Senior Secondary II in Greater Tomorrow Secondary School and the assistant prayer leader in
                                             her school before her death.    
                                            </p>
                                        </Typography>

                                    </ItemOne>
                                </Grid>
                        </Grid>
                        <Box sx={{flexGrow: 1, padding: 1, marginTop : 5}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}> 
                                <ItemOne>
                                        <Typography variant="h6" color="primary" sx={{fontWeight : 400}}>
                                            Death
                                        </Typography>
                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                            <p>
                                            On Sunday, the 15th of November, 2009, after preaching about purity, virginity, and martyrdom to her
                                        fellow children in the church in the morning, the opportunity came for her to put into practice what she
                                        preached all her life.
                                            </p>
                                            <p>
                                            While at home that evening, armed robbers came to rob her family and afterward took her to a nearby
                                        bush and attempted to rape her. She vehemently refused and opted to be shot instead and indeed she
                                        was martyred.
                                            </p>
                                        </Typography>
                                        <Typography variant="h6" color="primary" sx={{fontWeight : 400}}>
                                        Government Honours
                                        </Typography>
                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                            <p>
                                            Having learned the news of her heroic death, the government of Edo State granted the land where
                                        Vivian Ogu was martyred to the Catholic Archdiocese of Benin City. Two years later, the local
                                        government council of Ikpoba Okha officially named the street on which she was killed “Vivian Ogu”. On
                                        the 17th of September 2019, the Edo State government launched the Vivian Ogu Sexual Assault Referral
                                        Centre which provides services and support to survivors of sexual assault in Edo State.
                                            </p>
                                        </Typography>
                                </ItemOne>
                            </Grid>
                            <Grid item xs={12}> 
                                <ItemOne>
                                <Typography variant="h6" color="primary" sx={{fontWeight : 400}}>
                                Catholic Honours
                                        </Typography>
                                        <Typography variant="h6" sx={{fontWeight : 300}}>
                                        <p>
                                        Since the death of Vivian Ogu, the Catholic Archdiocese of Benin City has consistently celebrated
                                        Memorial Day for Vivian Ogu on the 15th of November every year. The Vivian Ogu Movement was
                                        inaugurated in 2014 by the Catholic Archbishop of Benin, Most Rev. Dr. Augustine Obiora Akubeze with
                                        the task of:
                                        </p>

                                        <List>
                                            <ListItem>
                                            Promoting the story of her exemplary life and heroic death so that many more may strive towards
                                        her heroic virtues and seek her intercession.
                                            </ListItem>
                                            <ListItem>
                                            Preserving and developing the land where she was killed.
                                            </ListItem>

                                        </List>

                                        <p>
                                        To celebrate the extraordinary missionary month in October 2019, the Holy Father Pope Francis asked
                                        for the biography of possible saint figures, canonized or not, to be used as heroic missionary examples
                                        for the whole world. Vivian Ogu was one of the twenty-five saint figures selected from the entire
                                        countries of the world.
                                        </p>

                                        <p>
                                        Consequently, on the 26th of October 2019, the entire Catholic Church in Nigeria gathered at Vivian Ogu
                                        Animation Centre (VOMAC) for the national celebration of the extraordinary missionary month of
                                        October. The Holy Father Pope Francis was represented by the president of the Pontifical Mission
                                        Society, Archbishop Giampietro Dal Toso.
                                        </p>
                                        </Typography>
                                </ItemOne>
                            </Grid>
                        </Grid>
                        </Box>
                          </Grid>
                      </Grid>
                    
                       
                    </Box>
                    <div class='circle xxlarge shade1'></div>
                    <div class='circle xlarge shade2'></div>
                    <div class='circle large shade3'></div>
                    <div class='circle mediun shade4'></div>
                    <div class='circle small shade5'></div>
                    </div>
           </Box>
        </>
    )
}


const itemData = [
    {
      img: vivian,
      rows: 2,
      cols: 2,
    },
    {
      img: vivCircle,
      cols: 2
    },
    {
      img: vivWomen,
      cols: 2,
    },
    {
      img: vivWomen,
      cols: 2,
    },
    {
      img: vivChoir,
      author: '@arwinneil',
      rows: 2,
      cols: 2,
    },
    {
      img: vivian,
      title: 'Basketball',
    },
    {
      img: vivian,
      title: 'Fern',
    },
  ];
  
export default AboutBody;