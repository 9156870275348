import React,{useState, useEffect} from "react";
import axios from "axios";
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"

const GetAllCompetitions = () => {


    useEffect(() => {
        getAllEvents()
    })

    const [competitions, setCompetition] = useState([]);

    const getAllEvents = async() => {
        await axios.get('api/users/getAllCompetitions').then(
            res => {
                console.log(res.data)
                setCompetition(res.data)
            }
        ).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            <Box padding={2} sx={{display: { xs: 'none', md: 'flex' }}}>
                <Grid spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h1" color="primary" sx={{fontWeight : 700}}>
                            Competitions
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>

                                {
                                    competitions.map(competition =>
                                        <Card key={competition._id} sx={{ maxWidth: "100%", marginBottom : 1 }}>
                                            <CardMedia component="img" height="300" image={competition.image} alt={competition.title}/>
                                            <Grid container stacing={2}>
                                                <Grid item xs={4}>
                                                    <Typography gutterBottom variant="h5" padding={1} sx={{marginBottom : 0, fontWeight : 700}} component="div">
                                                        {competition.title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                                        Start Date : {competition.start}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                                        End Date : {competition.end}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Typography gutterBottom variant="body1" padding={1} sx={{marginBottom : 0}} component="div">
                                                {competition.about}
                                            </Typography>


                                            <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                                Application channel
                                            </Typography>
                                            <Typography gutterBottom variant="body1" padding={1} sx={{marginBottom : 0}} component="div">
                                                {competition.channel}
                                            </Typography>

                                            <Typography gutterBottom variant="body1" color="error" padding={1} sx={{marginBottom : 0, fontWeight : 700}} component="div">
                                                Application Deadline : {competition.deadline}
                                            </Typography>
                                        </Card>)
                                }

                            </Grid>
                            <Grid item xs={4}>
                                <Card sx={{ maxWidth: "100%" }}>
                                    <CardMedia
                                        component="img"
                                        height="250"
                                        image={require('../../../src/images/vivian.jpg')}
                                        alt="green iguana"
                                    />

                                    <Typography gutterBottom variant="h5" padding={1} component="div">
                                        Vivian Ogu
                                    </Typography>
                                    <Typography variant="body2" padding={1} color="text.secondary">
                                        Vivian Uchechi Ogu was a 14-year-old girl who lived an exemplary holy life through her missionary works
                                        and died a heroic death by opting to be killed rather than being sexually defiled.
                                    </Typography>

                                </Card>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Box padding={2} sx={{display: { xs: 'flex', md: 'none' }}}>
                <Grid>
                    <Grid item xs={12}>
                    <Typography variant="h3" color="primary" sx={{fontWeight : 700}}>
                                            Competitions
                                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>

                    {
                competitions.map(competition => 
                <Card key={competition._id} sx={{ maxWidth: "100%", marginBottom : 1 }}>
                    <CardMedia component="img" height="300" image={competition.image} alt={competition.title}/>          
                            <Grid container stacing={2}>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="h5" padding={1} sx={{marginBottom : 0, fontWeight : 700}} component="div">
                                        {competition.title} 
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                        Start Date : {competition.start} 
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                        End Date : {competition.end} 
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography gutterBottom variant="body1" padding={1} sx={{marginBottom : 0}} component="div">
                                {competition.about}
                            </Typography>


                            <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                        Application channel 
                                    </Typography>
                            <Typography gutterBottom variant="body1" padding={1} sx={{marginBottom : 0}} component="div">
                                {competition.channel}
                            </Typography>

                            <Typography gutterBottom variant="body1" color="error" padding={1} sx={{marginBottom : 0, fontWeight : 700}} component="div">
                                Application Deadline : {competition.deadline}
                            </Typography>
                </Card>)
            }
                        
                    </Grid>
                    <Grid item xs={12}>
                    <Card sx={{ maxWidth: "100%" }}>
                                        <CardMedia
                                            component="img"
                                            height="250"
                                            image={require('../../../src/images/vivian.jpg')}
                                            alt="green iguana"
                                        />
                           
                                            <Typography gutterBottom variant="h5" padding={1} component="div">
                                            Vivian Ogu
                                            </Typography>
                                            <Typography variant="body2" padding={1} color="text.secondary">
                                            Vivian Uchechi Ogu was a 14-year-old girl who lived an exemplary holy life through her missionary works
        and died a heroic death by opting to be killed rather than being sexually defiled.
                                            </Typography>
                        
                                    </Card>
                            
                    </Grid>
                </Grid>
                    </Grid>
                </Grid>
    
           
            </Box>
        </>
    )
}

export default GetAllCompetitions;