import React,{useState, useEffect} from "react";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid";
import axios from "axios";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button"
import ShowEventsAdmin from "./showEventsAdmin";
import AutoSlider from "./adminSlider"

const ItemOne = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(4),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    }));


const AdminEventBody = () => {

    const [event, setEvent] = useState({
        title : '', 
        body : ''
    })
    const {title, body} = event;
    const [image, setOnChangeImage] = useState();
  
    const formData = new FormData();
    //append all properties
    formData.append('title', title);
    formData.append('body', body);
    formData.append('image', image);

    const config = {
        headers : {
            'Content-Type' : 'multipart/form-data',
        }
    }

    //handle form submission
    const handleSubmit = async(e) => {
        e.preventDefault();
        
       await axios.post('api/users/uploadEvent', formData, config).
        then( function (response) {

            alert("Event uploaded successfully");
            
        }).catch(err => {
            console.log(err)
        })
    }


    return (

        <>
            <Box flexGrow={1} padding={2} sx={{display: { xs: 'none', md: 'flex' }}}> 
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="h4" sx={{fontWeight : 200, color : "white"}}>
                                Add New Event
                        </Typography>
                            <ItemOne>
                                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <TextField onChange={(e) =>  setEvent((event) => ({...event, title : e.target.value}))} variant="outlined" fullWidth label="Title" type="text" name="title"  value={event.title} />
                                    <TextField onChange={(e) =>  setEvent((event) => ({...event, body : e.target.value}))} variant="outlined" multiline maxRows={10} fullWidth label="Body" name="body" type="text" sx={{marginTop : 1}} value={event.body} />
                                    <TextField type="file" onChange={(e) =>  setOnChangeImage(e.target.files[0])} sx={{marginBottom : 1, marginTop : 1}} fullWidth name="image" />
                                    <Button type="submit" color="primary" variant="contained" sx={{marginTop : 1}}>
                                        Upload to page
                                    </Button>
                                </form>
                            </ItemOne>
                    </Grid>
                    <Grid item xs={6}>
                        <ShowEventsAdmin />
                    </Grid>
                </Grid>
            </Box>

            <Box flexGrow={1} padding={2} sx={{display: { xs: 'flex', md: 'none' }}}> 
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{fontWeight : 200, color : "white"}}>
                                Add New Event
                        </Typography>
                            <ItemOne>
                                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <TextField onChange={(e) =>  setEvent((event) => ({...event, title : e.target.value}))} variant="outlined" fullWidth label="Title" type="text" name="title"  value={event.title} />
                                    <TextField onChange={(e) =>  setEvent((event) => ({...event, body : e.target.value}))} variant="outlined" multiline maxRows={10} fullWidth label="Body" name="body" type="text" sx={{marginTop : 1}} value={event.body} />
                                    <TextField type="file" onChange={(e) =>  setOnChangeImage(e.target.files[0])} sx={{marginBottom : 1, marginTop : 1}} fullWidth name="image" />
                                    <Button type="submit" color="primary" variant="contained" sx={{marginTop : 1}}>
                                        Upload to page
                                    </Button>
                                </form>
                            </ItemOne>
                    </Grid>
                    <Grid item xs={12}>
                        <ShowEventsAdmin />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default AdminEventBody;