import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'
import ShowEventsOnHomePage from "./showEventsOnHome";



const HomePageEvents = () => {

    return (
        <>

                <Box flexGrow={1} padding={5} sx={{display: { xs: 'none', md: 'flex' }}}>
                       <Grid>
                           <Grid item xs={12}>
                               <Typography variant="h2" color="primary" sx={{fontWeight : 700}}>
                                   Event Highlights
                               </Typography>
                           </Grid>
                           <Grid item xs={12}>
                               <ShowEventsOnHomePage />
                           </Grid>
                       </Grid>
                    </Box>

                      <Box flexGrow={1} padding={1} sx={{display: { xs: 'flex', md: 'none' }}}>
                        <Grid>
                            <Grid item xs={12}>
                            <Typography variant="h3" color="primary" sx={{fontWeight : 700}}>
                            Event Highlights
                        </Typography>
                            </Grid>
                            <Grid item xs={12}>
                            <ShowEventsOnHomePage />
                            </Grid>
                        </Grid>
                    </Box>
        
        </>
    )
}

export default HomePageEvents;