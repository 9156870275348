import React from "react";
import Footer from "./components/footer";
import GetAllCompetitions from "./components/getCompetitions";
import Navigation from "./components/nav";

const Competitions = () => {

    return (

        <>
            <Navigation />
            <GetAllCompetitions />
            <Footer />
        </>
    )
}
export default Competitions;
