import React from "react";
import './App.css';
import {Routes, Route, Navigate} from "react-router-dom";
import Home from "./pages/home"
import About from "./pages/about";
import Register from "./pages/register";
import Login from "./pages/login";
import Dashboard from "./pages/adminDashboard";
import AdminEvent from "./pages/adminEvents";
import Events from "./pages/events";
import AdminCompetitions from "./pages/adminCompetition";
import Competitions from "./pages/competitions";
import Testimonies from "./pages/testimonies";
import AdminTestimonies from "./pages/adminTestimonies";
import AdminDonate from "./pages/adminDonate";
import Contact from "./pages/contact";
import Donate from "./pages/components/Donate";
import Gallery from "./pages/gallery";
import AdminGallery from "./pages/adminGallery";
import Updates from "./pages/adminUpdates";
import HomeUpdates from "./pages/homeUpdate";
import ProtectedRoutes from "./protectRoute";


function App() {

  const checkTwo = localStorage.getItem("Auth");
  
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/About" exact element={<About />} />
      <Route path="/Gallery" exact element={<Gallery />}/>
      <Route path="/register" exact element={<Register />} />
      <Route path="/admin/login" exact element={<Login />} />
      <Route path="/Competitions" exact element={<Competitions />} />
      <Route path="/Testimonies" exact element={<Testimonies />}/>
      <Route path="/Contact" exact element={<Contact />} />
      <Route path="/Donate" exact element={<Donate />} />
      <Route path="/Updates" exact element={<HomeUpdates />}/>
      <Route path="/Events" exact element={<Events />} />
      <Route path="/admin/dashboard" exact element={<Dashboard />} />
      <Route path="/admin/events" exact element={<AdminEvent />} />
      <Route path="/admin/donate" exact element={<AdminDonate />} />
      <Route path="/admin/Gallery" exact element={<AdminGallery />} />
      <Route path="/admin/Updates" exact element={<Updates />}/>
      <Route path="/admin/competitions" exact element={<AdminCompetitions />} />
      <Route path="/admin/Testimonies" exact element={<AdminTestimonies />} />      
    </Routes>
  );
}

export default App;
