import React, {useState, useEffect} from "react";
import AdminNav from "./components/adminNav";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid";
import axios from "axios";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ShowCompetitionAdmin from "./components/showCompetionsAdmin"
import Home from "./home";


const ItemOne = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(4),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const AdminCompetitions = () => {

    const [competition, setCompetition] = useState({
        title : '', 
        about : '', 
        start : '',
        end : '', 
        channel : '', 
        deadline : '', 
    })
    const {title, about, start, end, channel, deadline} = competition;
    const [image, setOnChangeImage] = useState();
  
    const formData = new FormData();
    //append all properties
    formData.append('title', title);
    formData.append('about', about);
    formData.append('start', start);
    formData.append('end', end);
    formData.append('channel', channel);
    formData.append('deadline', deadline);
    formData.append('image', image);

    const config = {
        headers : {
            'Content-Type' : 'multipart/form-data',
        }
    }
    //handle form submission
    const handleSubmit = async(e) => {
        e.preventDefault();
       await axios.post('api/users/uplaodCompetition', formData, config).
        then( function (response) {

            alert("Competition uploaded successfully");
            setCompetition({
                title : '', 
                about : '', 
                start : '',
                end : '', 
                channel : '', 
                deadline : '', 
            })
            
        }).catch(err => {
            console.log(err)
        })
    }


    //check to see if user is authenticated
    const check = localStorage.getItem('Auth')
    console.log(check)


    return (
        check == null ? 
            <Home />
        :
            check == "true" ?
            <>
            <AdminNav />
            <Box flexGrow={1} padding={2} sx={{display: { xs: 'none', md: 'flex' }}}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="h4" sx={{fontWeight : 200, color : "white"}}>
                                Add New Competition
                        </Typography>
                            <ItemOne>
                                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <TextField onChange={(e) =>  setCompetition((competition) => ({...competition, title : e.target.value}))} variant="outlined" fullWidth label="Title of competition" type="text" name="title"  value={competition.title} />
                                    <TextField onChange={(e) =>  setCompetition((competition) => ({...competition, about : e.target.value}))} variant="outlined" multiline maxRows={10} fullWidth label="About Competition" name="body" type="text" sx={{marginTop : 1}} value={competition.about} />
                                    <TextField onChange={(e) =>  setCompetition((competition) => ({...competition, start : e.target.value}))} variant="outlined" multiline fullWidth label="Start Date" name="body" type="text" sx={{marginTop : 1}} value={competition.start} />
                                    <TextField onChange={(e) =>  setCompetition((competition) => ({...competition, end : e.target.value}))} variant="outlined" multiline fullWidth label="End Date " name="body" type="text" sx={{marginTop : 1}} value={competition.end} />
                                    <TextField onChange={(e) =>  setCompetition((competition) => ({...competition, channel : e.target.value}))} variant="outlined" multiline fullWidth label="Application Channel (Link to application form or other description)" name="body" type="text" sx={{marginTop : 1}} value={competition.channel} />
                                    <TextField onChange={(e) =>  setCompetition((competition) => ({...competition, deadline : e.target.value}))} variant="outlined" multiline fullWidth label="Application Deadline" name="body" type="text" sx={{marginTop : 1}} value={competition.deadline} />
                                    <TextField type="file" onChange={(e) =>  setOnChangeImage(e.target.files[0])} sx={{marginBottom : 1, marginTop : 1}} fullWidth name="image" />
                                    <Button type="submit" color="primary" variant="contained" sx={{marginTop : 1}}>
                                        Upload to page
                                    </Button>
                                </form>
                            </ItemOne>
                    </Grid>
                    <Grid item xs={8}>
                      <ShowCompetitionAdmin />
                    </Grid>
                </Grid>
            </Box>

            <Box flexGrow={1} padding={2} sx={{display: { xs: 'flex', md: 'none' }}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{fontWeight : 200, color : "white"}}>
                                Add New Competition
                        </Typography>
                            <ItemOne>
                                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <TextField onChange={(e) =>  setCompetition((competition) => ({...competition, title : e.target.value}))} variant="outlined" fullWidth label="Title of competition" type="text" name="title"  value={competition.title} />
                                    <TextField onChange={(e) =>  setCompetition((competition) => ({...competition, about : e.target.value}))} variant="outlined" multiline maxRows={10} fullWidth label="About Competition" name="body" type="text" sx={{marginTop : 1}} value={competition.about} />
                                    <TextField onChange={(e) =>  setCompetition((competition) => ({...competition, start : e.target.value}))} variant="outlined" multiline fullWidth label="Start Date" name="body" type="text" sx={{marginTop : 1}} value={competition.start} />
                                    <TextField onChange={(e) =>  setCompetition((competition) => ({...competition, end : e.target.value}))} variant="outlined" multiline fullWidth label="End Date " name="body" type="text" sx={{marginTop : 1}} value={competition.end} />
                                    <TextField onChange={(e) =>  setCompetition((competition) => ({...competition, channel : e.target.value}))} variant="outlined" multiline fullWidth label="Application Channel (Link to application form or other description)" name="body" type="text" sx={{marginTop : 1}} value={competition.channel} />
                                    <TextField onChange={(e) =>  setCompetition((competition) => ({...competition, deadline : e.target.value}))} variant="outlined" multiline fullWidth label="Application Deadline" name="body" type="text" sx={{marginTop : 1}} value={competition.deadline} />
                                    <TextField type="file" onChange={(e) =>  setOnChangeImage(e.target.files[0])} sx={{marginBottom : 1, marginTop : 1}} fullWidth name="image" />
                                    <Button type="submit" color="primary" variant="contained" sx={{marginTop : 1}}>
                                        Upload to page
                                    </Button>
                                </form>
                            </ItemOne>
                    </Grid>
                    <Grid item xs={12}>
                      <ShowCompetitionAdmin />
                    </Grid>
                </Grid>
            </Box>
            </>
            :
                <Home />
    )
}

export default AdminCompetitions;