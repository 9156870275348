import React,{useState, useEffect} from "react";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";

const ItemOne = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(4),
    textAlign: 'left',
    marginBottom : 5,
    color: theme.palette.text.secondary,
}));

const PullUpdates = () => {

    useEffect(() => {
        getPost()
    });

    //create state for post fetched
    const [Gpost, setGottenPost] = useState([])


    const getPost = async() => {

        await axios.get('api/users/getUpdates').
        then(res => {
            setGottenPost(res.data);
        }).catch(err => {
            // alert(err);
        })
    }

    const handleDelete = async(e, _id) => {
        await axios.delete('api/users/deleteUpdate/'+_id).then(
            res => {
                alert(res.data)
                getPost();
            }
        ).catch(err => {
            alert(err)
        })
    }


    return (
        <>

            {
                Gpost.map(post => <ItemOne key={post._d}>
                    <Typography variant="h5" sx={{fontWeight : 500}}>
                        {post.title}
                    </Typography>
                    <Typography variant="body1" sx={{fontWeight : 200}}>
                        {post.body}
                    </Typography>
                    <Button variant="contained" color='error' sx={{marginTop : 1}} onClick={(e) => handleDelete(e, post._id)}>
                        Delete
                    </Button>
                </ItemOne>)
            }
        </>
    )
}

export default PullUpdates;