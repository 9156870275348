import React,{useState, useEffect} from "react";
import AdminNav from "./components/adminNav";
import AdminEventBody from "./components/adminEventBody";
import Home from "./home";

const AdminEvent = () => {


    //check to see if user is authenticated
    const check = localStorage.getItem('Auth')
    console.log(check)

    
        return (
            check == null ? 
                <Home />
            :
                check == "true" ?
                    <>
                        <AdminNav />
                        <AdminEventBody />
                    </>
                :
                    <Home />
        )

}

export default AdminEvent;