import React, {useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button"
import axios from 'axios';
import { useNavigate } from "react-router";
import TextField from "@mui/material/TextField"


const ItemOne = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(4),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

function RegForm(){

    //registration error
    const [regError, setRegError] = useState();
   // use navigate
    let navigate = useNavigate();

    //set state for registration data
    const [regData, setRegData] = useState({
        email : "", 
        password : "", 
        password2 : ""
    });

    //handle the form submission event
    const handleSubmit = async(e) => {
        console.log(regData)
        e.preventDefault();
      if (regData.password === regData.password2) {
            // console.log(regData)
          axios.post('api/users/adminRegister', regData)
          .then(function (response) {
            if(response.statusText === 'OK'){
                navigate('/admin/login')
            }else{
                setRegData({
                    email : "", 
                    password : "", 
                    password2 : "",
                })
            }
          });
      }else{
        setRegError("Passwords do not match, try again...");
        setRegData({
            email : "", 
            password : "", 
            password2 : "",
        })
      }
    };


    return (
        <>
            <Box flexGrow={1} padding={5} mt={5} sx={{display: { xs: 'none', md: 'flex' }}}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={6}>
                        <ItemOne>
                            <Typography variant="h4" sx={{fontWeight : 200}}>
                                Create Admin Account
                            </Typography>
                        
                            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <TextField  onChange={(e) =>  setRegData((regData) => ({...regData, email : e.target.value}))} name="email" fullWidth required sx={{marginTop : 2}} type="email" label="Email" variant="outlined" value={regData.email}/>
                                <TextField  onChange={(e) =>  setRegData((regData) => ({...regData, password : e.target.value}))} name="password" fullWidth required sx={{marginTop : 1}} type="password"  label="Password" variant="outlined" value={regData.password}/>
                                <TextField  onChange={(e) =>  setRegData((regData) => ({...regData, password2 : e.target.value}))} name="password2" fullWidth required sx={{marginTop : 1, display : "block"}} type="password"  label="Confirm password" variant="outlined" value={regData.password2}/>
                                <Button type="submit" color="primary" variant="contained" sx={{marginTop : 1, width : "100%", fontWeight : 700}}>
                                    Create account
                                </Button>
                            </form>
                  
                        </ItemOne>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                </Grid>
            </Box>

            <Box flexGrow={1} padding={1} sx={{display: { xs: 'flex', md: 'none' }}}>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <ItemOne>
                            <Typography variant="h5" sx={{fontWeight : 200}}>
                                Create Admin Account
                            </Typography>

                            <form autoComplete="on" onSubmit={handleSubmit}>
                                <TextField  onChange={(e) =>  setRegData((regData) => ({...regData, email : e.target.value}))} name="email" fullWidth required sx={{marginTop : 2}} type="email" label="Email" variant="outlined" value={regData.email}/>
                                <TextField  onChange={(e) =>  setRegData((regData) => ({...regData, password : e.target.value}))} name="password" fullWidth required sx={{marginTop : 1}} type="password"  label="Password" variant="outlined" value={regData.password}/>
                                <TextField  onChange={(e) =>  setRegData((regData) => ({...regData, password2 : e.target.value}))} name="password2" fullWidth required sx={{marginTop : 1, display : "block"}} type="password"  label="Confirm password" variant="outlined" value={regData.password2}/>
                                <Button type="submit" color="primary" variant="contained" sx={{marginTop : 1, width : "100%", fontWeight : 700}}>
                                    Create account
                                </Button>
                            </form>
                        </ItemOne>
                    </Grid>

                </Grid>
            </Box>
        </>
    )
}

export default RegForm;