import React from "react";
import AdminNav from "./components/adminNav";
import AdminTestimoniesBody from "./components/adminTestimoniesBody"
import Home from "./home";

const AdminTestimonies = () => {


    //check to see if user is authenticated
    const check = localStorage.getItem('Auth')
    console.log(check)

    
        return (
            check == null ? 
                <Home />
            :
                check == "true" ?
                    <>
                        <AdminNav />
                        <AdminTestimoniesBody/>
                    </>
                :
                    <Home />
        )

}

export default AdminTestimonies;