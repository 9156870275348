import React,{useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid";
import axios from "axios";
import Typography from '@mui/material/Typography';

const ItemOne = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  }));


const AutoSlider = () => {

    useEffect(() => {
        donatingAmount()
    })

    //state for donating amount
    const[donate, setDonating] = useState({
      "amount" : 0,
      "target" : 0
  });

     //pull
     const donatingAmount = () => {
            axios.get('api/users/getDonating').then(res => {
            setDonating(res.data)
        })
    }

       

    return (
        <>
            <Box flexGrow={1} padding={2} mt={8} sx={{display: { xs: 'none', md: 'flex' }}} >
                <Grid container spacing={2}>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={6}>
                        <ItemOne>
                          
                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item xs={4}>
                                <Typography variant='h6' align='right' sx={{fontWeight : 500}}>
                                  {(donate.amount).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                </Typography>
                                <h6 align="right" style={{margin : "0px", padding : "0px"}}><small style={{color : "green", fontWeight : 700, fontSize : "1.1em"}}>Bricks Raised so far</small></h6>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    |
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                <Typography variant="h5" align='left' sx={{fontWeight : 700}}>
                                  {(donate.target).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                </Typography>
                                <h6 align="left" style={{margin : "0px", padding : "0px"}}><small style={{color : "blue", fontWeight : 700, fontSize : "1.1em"}}>Bricks Needed</small></h6>
                                </Grid>
                            </Grid>
                        </ItemOne>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                </Grid>
            </Box>
            <Box flexGrow={1} padding={2} mt={8} sx={{display: { xs: 'flex', md: 'none' }}} >
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <ItemOne>
                          
                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item xs={4}>
                                <Typography variant='h6' align='right' sx={{fontWeight : 500}}>
                                  {(donate.amount).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                </Typography>
                                <h6 align="right" style={{margin : "0px", padding : "0px"}}><small style={{color : "green", fontWeight : 700, fontSize : "1.1em"}}>Bricks Raised so far</small></h6>
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    |
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                <Typography variant="h5" align='left' sx={{fontWeight : 700}}>
                                  {(donate.target).toLocaleString(undefined, { maximumFractionDigits: 2 })} 
                                </Typography>
                                <h6 align="left" style={{margin : "0px", padding : "0px"}}><small style={{color : "blue", fontWeight : 700, fontSize : "1.1em"}}>Bricks Needed</small></h6>
                                </Grid>
                            </Grid>
                        </ItemOne>
                    </Grid>
                   
                </Grid>
            </Box>
        </>
    )
}



export default AutoSlider;