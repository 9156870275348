import React,{useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid";
import axios from "axios";
import Typography from '@mui/material/Typography';
import {Link} from "react-router-dom"
import Button from "@mui/material/Button"
import Money from "@mui/icons-material/Money"
import { Container } from '@mui/system';


const ItemOne = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  }));


const HomePagesDonate = () => {

    useEffect(() => {
        donatingAmount()
    })

    //state for donating amount
    const[donate, setDonating] = useState({
      "amount" : 0,
      "target" : 0
  });

     //pull
     const donatingAmount = () => {
            axios.get('api/users/getDonating').then(res => {
            setDonating(res.data)
        })
    }

       

    return (

        <>
           <Container maxWidth="xl">
           <Box flexGrow={1} paddingBottom={0} mt={9} sx={{display: { xs: 'none', md: 'flex' }}} >
                <Grid container spacing={2}>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={6}>
                        <ItemOne>
                          
                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item xs={3}>
                                <Typography variant='h6' align='right' sx={{fontWeight : 500}}>
                                  {(donate.amount).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                </Typography>
                                <h6 align="right" style={{margin : "0px", padding : "0px"}}><small style={{color : "green", fontWeight : 700, fontSize : "1.1em"}}>Bricks Raised so far</small></h6>
                                </Grid>
                                <Grid item>
                                  <Typography align='right'>
                                    |
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                <Typography variant="h5" align='left' sx={{fontWeight : 700}}>
                                  {(donate.target).toLocaleString(undefined, { maximumFractionDigits: 2 })} 
                                </Typography>
                                <h6 align="left" style={{margin : "0px", padding : "0px"}}><small style={{color : "blue", fontWeight : 700, fontSize : "1.1em"}}>Bricks Needed</small></h6>
                                </Grid>
                                <Grid item xs={4}>
                                    <Link to="/Donate" style={{textDecoration : "none"}}>
                                        <Button color="primary" variant="contained" >
                                            Donate Now!
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </ItemOne>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                </Grid>
            </Box>


            <Box container flexGrow={1} paddingBottom={0} mt={8} sx={{display: { xs: 'flex', md: 'none' }}} >
            <ItemOne>
                          
                          <Grid container maxWidth="xl" justifyContent="center" spacing={1}>
                              <Grid item xs={5}>
                              <Typography variant='h6' align='right' sx={{fontWeight : 500}}>
                                {(donate.amount).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                              </Typography>
                              <h6 align="right" style={{margin : "0px", padding : "0px"}}><small style={{color : "green", fontWeight : 700, fontSize : "1.1em"}}>Bricks Raised so far</small></h6>
                              </Grid>
                              <Grid item>
                                <Typography align='right'>
                                  |
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                              <Typography variant="h5" align='left' sx={{fontWeight : 700}}>
                                {(donate.target).toLocaleString(undefined, { maximumFractionDigits: 2 })} 
                              </Typography>
                              <h6 align="left" style={{margin : "0px", padding : "0px"}}><small style={{color : "blue", fontWeight : 700, fontSize : "1.1em"}}>Bricks Needed</small></h6>
                              </Grid>


                              <Grid>
                                  <Grid item xs={12}>
                                      <Link to="/Donate" style={{textDecoration : "none"}}>
                                          <Button color="primary" variant="contained" >
                                              Donate Now!
                                          </Button>
                                      </Link>
                                  </Grid>
                              </Grid>
                          </Grid>

                      </ItemOne>
            </Box>
           </Container>
        </>
    )
}

export default HomePagesDonate;