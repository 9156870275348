import React,{useState, useEffect} from "react";
import axios from "axios";
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardActions";
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Whatsapp from "@mui/icons-material/WhatsappRounded"


const ItemOne = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(4),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

const SeeTestimonies = () => {


    useEffect(() => {
        getAllTestimonies()
    })

    const [competitions, setCompetition] = useState([]);

    const getAllTestimonies = async() => {
        await axios.get('api/users/getAllTestimonies').then(
            res => {
                console.log(res.data)
                setCompetition(res.data)
            }
        ).catch(err => {
            console.log(err);
        })
    }


    return (
        <>
             <Box padding={2} sx={{display: { xs: 'none', md: 'flex' }}}>
                 <Grid spacing={2}>
                     <Grid item xs={12}>
                         <Typography variant="h1" color="primary" sx={{fontWeight : 700}}>
                             Testimonies
                         </Typography>
                     </Grid>
                     <Grid item xs={12}>
                         <Grid container spacing={2}>

                             <Grid item xs={4}>
                                 <ItemOne>
                                     <Typography variant="h5" sx={{fontWeight : 700}}>
                                         Share your testimonies via our care lines
                                     </Typography>
                                     <Grid container spacing="2">
                                         <Grid item xs={6}>
                                             <a href="https://api.whatsapp.com/send?phone=2348035643063" style={{textDecoration : 'none'}}>
                                                 <Button fullWidth variant="contained" color="success" sx={{padding : 2}}>
                                                     <Whatsapp />  Line One
                                                 </Button>
                                             </a>
                                         </Grid>
                                         <Grid item xs={6}>
                                             <a href="https://api.whatsapp.com/send?phone=2349155257413" style={{textDecoration : 'none'}}>
                                                 <Button fullWidth variant="contained" color="success" sx={{padding : 2}}>
                                                     <Whatsapp />  Line Two
                                                 </Button>
                                             </a>
                                         </Grid>
                                     </Grid>
                                 </ItemOne>
                             </Grid>

                             <Grid item xs={6}>

                                 {
                                     competitions.map(competition =>
                                         <Card key={competition._id} sx={{ maxWidth: "100%", marginBottom : 1 }}>
                                             <CardMedia component="img"  image={competition.image} alt={competition.title}/>
                                             <Grid container stacing={2}>
                                                 <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                                     {competition.title}
                                                 </Typography>
                                                 <Typography gutterBottom variant="body1" padding={1} sx={{marginBottom : 0}} component="div">
                                                     {competition.body}
                                                 </Typography>
                                             </Grid>
                                         </Card>)
                                 }

                             </Grid>

                         </Grid>
                     </Grid>
                 </Grid>
            </Box>



            <Box padding={2} sx={{display: { xs: 'flex', md: 'none' }}}>

            <Grid>
                <Grid item xs={12}>
                <Typography variant="h3" color="primary" sx={{fontWeight : 700}}>
                                            Testimonies
                                        </Typography>
                </Grid>
                <Grid>
                <Grid container spacing={2}>
               
               <Grid item xs={12}>
                   <ItemOne>
                       <Typography variant="h5" sx={{fontWeight : 700}}>
                           Share your testimonies via our care lines
                       </Typography>
                      <Grid container spacing="2">
                           <Grid item xs={6}>
                               <a href="https://api.whatsapp.com/send?phone=2348035643063" style={{textDecoration : 'none'}}>
                                   <Button fullWidth variant="contained" color="success" sx={{padding : 2}}>
                                   <Whatsapp /> Line One
                                   </Button>
                               </a>
                           </Grid>
                           <Grid item xs={6}>
                               <a href="https://api.whatsapp.com/send?phone=2349155257413" style={{textDecoration : 'none'}}>
                                   <Button fullWidth variant="contained" color="success" sx={{padding : 2}}>
                                   <Whatsapp /> Line Two
                                   </Button>
                               </a>
                           </Grid>
                      </Grid>
                   </ItemOne>
               </Grid>
      
               <Grid item xs={12}>

               {
           competitions.map(competition => 
           <Card key={competition._id} sx={{marginBottom : 1 }}>
               <CardMedia component="img"  image={competition.image} alt={competition.title}/>          
                       <Grid container stacing={2}>
                       <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                   {competition.title} 
                               </Typography>
                       <Typography gutterBottom variant="body1" padding={1} sx={{marginBottom : 0}} component="div">
                           {competition.body}
                       </Typography>
                   </Grid>
           </Card>)
       }
                   
               </Grid>
               
           </Grid>
                </Grid>
            </Grid>
            </Box>
        </>
    )
}

export default SeeTestimonies;