import React,{useState} from "react";
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button"
import axios from 'axios';
import { useNavigate } from "react-router";
import TextField from "@mui/material/TextField"
import ProtectedRoutes from "../../protectRoute";
import Dashboard from "../adminDashboard";
import Home from "../home";


const ItemOne = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(4),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));


const LoginForm = () => {


    //registration error
    const [loginError, setLoginError] = useState();
    // use navigate
    let navigate = useNavigate();
    //set state for registration data
    //useState for login data
    const [loginData, setLoginData] = useState({
        email : "", 
        password : ""
    });

    const [isAuth, setAuth] = useState();

    const handleSubmit = async(e) => {
        //prevent default sudmit
        e.preventDefault();
    
          //send post request with axios
          axios.post('api/users/adminLogin', loginData).then(function (response) {
             //save token to local storage to remember user
             localStorage.setItem('token', response.data.token)
             localStorage.setItem('Auth', response.data.auth)
             console.log(response.data)
              if(response.data.success = false){
                  setLoginError("Invalid Email or Password, try again..")
              }else{
                
                setAuth(localStorage.getItem("Auth"));

                navigate("/admin/dashboard");
              }
          })

          setLoginData({
            email : "", 
            password : ""
        })
    
    }

    return (
            <>
                <Box flexGrow={1} padding={5} sx={{display: { xs: 'none', md: 'flex' }}}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>

                    </Grid>
                    <Grid item xs={6}>
                        <ItemOne>
                            <Typography variant="h4" sx={{fontWeight : 200}}>
                                Login To Admin Account
                            </Typography>
                        
                            <form noValidate autoComplete="on" onSubmit={handleSubmit}>
                                <TextField  onChange={(e) =>  setLoginData((loginData) => ({...loginData, email : e.target.value}))} name="email" fullWidth required sx={{marginTop : 2}} type="email" label="Email" variant="outlined" value={loginData.email}/>
                                <TextField  onChange={(e) =>  setLoginData((loginData) => ({...loginData, password : e.target.value}))} name="password" fullWidth required sx={{marginTop : 1}} type="password"  label="Password" variant="outlined" value={loginData.password}/>
                                <Button type="submit" color="primary" variant="contained" sx={{marginTop : 1, width : "100%", fontWeight : 700}}>
                                    Login
                                </Button>
                            </form>
                    
                        </ItemOne>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                </Grid>
                </Box>

                <Box flexGrow={1} padding={1} sx={{display: { xs: 'flex', md: 'none' }}}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <ItemOne>
                                <Typography variant="h5" sx={{fontWeight : 200}}>
                                    Login To Admin Account
                                </Typography>

                                <form autoComplete="on" onSubmit={handleSubmit}>
                                    <TextField  onChange={(e) =>  setLoginData((loginData) => ({...loginData, email : e.target.value}))} name="email" fullWidth required sx={{marginTop : 2}} type="email" label="Email" variant="outlined" value={loginData.email}/>
                                    <TextField  onChange={(e) =>  setLoginData((loginData) => ({...loginData, password : e.target.value}))} name="password" fullWidth required sx={{marginTop : 1}} type="password"  label="Password" variant="outlined" value={loginData.password}/>
                                    <Button type="submit" color="primary" variant="contained" sx={{marginTop : 1, width : "100%", fontWeight : 700}}>
                                        Login
                                    </Button>
                                </form>

                            </ItemOne>
                        </Grid>

                    </Grid>
                </Box>
            </>
    )
}

export default LoginForm;