import { Container, Box} from "@mui/system";
import React from "react";
import Grid from "@mui/material/Grid"
import Typography  from "@mui/material/Typography";
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom"
import Button from "@mui/material/Button"
import Instagram from "@mui/icons-material/Instagram"
import YouTube from "@mui/icons-material/YouTube";
import Twitter from "@mui/icons-material/Twitter"
import Facebook from "@mui/icons-material/Facebook"
import WhatsApp from "@mui/icons-material/WhatsApp";


const Footer = () => {
    return (
        <>
        
            <Box sx={{padding : 10, display: { xs: 'none', md: 'flex' }}} style={{backgroundColor : "black"}}>
                    <Grid container>
                        <Grid item xs={3}>
                            <img 
                                src={require('../../../src/images/circleVivian.png')}
                                style={{width : "150px", height : "150px", marginRight : "10px", float : "center"}}
                            />
                        
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h4" mt={1} style={{color : "white", fontWeight : 700}}>
                                Quick links
                            </Typography>
                            <List>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                    <Link to="/Events" style={{textDecoration : 'none', color : 'white'}}>
                                        Events
                                    </Link>
                                </ListItem>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                    <Link to="/Competitions" style={{textDecoration : 'none', color : 'white'}}>
                                        Competitions
                                    </Link>
                                </ListItem>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                    <Link to="/Donate" style={{textDecoration : 'none', color : 'white'}}>
                                        Donate
                                    </Link>
                                </ListItem>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                    <Link to="/About" style={{textDecoration : 'none', color : 'white'}}>
                                        About
                                    </Link>
                                </ListItem>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                    <Link to="/Contact" style={{textDecoration : 'none', color : 'white'}}>
                                        Contact
                                    </Link>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h4" mt={1} style={{color : "white", fontWeight : 700}}>
                                Donation
                            </Typography>
                            <List>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                Naira Account Details:<br/>
                                Bank Name: Zenith Bank
                                Account Name: Vivian Ogu Movement Benin City.
                                Account Number: 1013793121
                                </ListItem>
                            </List>
                            <Grid container spacing={1}>
                            <Grid item>
                                        <a href="https://www.instagram.com/accounts/login/?next=/vivianogumovement/" style={{textDecoration : 'none'}}>
                                            <Button variant="contained" >
                                                <Instagram />
                                            </Button>
                                        </a>
                                        </Grid>
                                        <Grid item>
                                        <a href="https://www.instagram.com/accounts/login/?next=/vivianogumovement/" style={{textDecoration : 'none'}}>
                                            <Button variant="contained" color="error">
                                                <YouTube />
                                            </Button>
                                        </a>
                                        </Grid>
                                        <Grid item>
                                        <a href="https://twitter.com/VO_Movement?t=I24Co1ia3FCBaLhThShL-w&s=09" style={{textDecoration : 'none'}}>
                                            <Button variant="contained">
                                                <Twitter />
                                            </Button>
                                        </a>
                                        </Grid>
                                        <Grid item>
                                        <a href="https://www.facebook.com/Vivian-Ogu-Movement-110675584791777/" style={{textDecoration : 'none'}}>
                                            <Button variant="contained">
                                                <Facebook />
                                            </Button>
                                        </a>
                                        </Grid>
                                        <Grid item>
                                        <a href="https://api.whatsapp.com/send?phone=2348035643063" style={{textDecoration : 'none'}}>
                                            <Button variant="contained" color="success">
                                                <WhatsApp /> One
                                            </Button>
                                        </a>
                                        </Grid>
                                        <Grid item>
                                        <a href="https://api.whatsapp.com/send?phone=2349155257413" style={{textDecoration : 'none'}}>
                                            <Button variant="contained" color="success">
                                                <WhatsApp /> Two
                                            </Button>
                                        </a>
                                        </Grid>
                                </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h4" mt={1} style={{color : "white", fontWeight : 700}}>
                                &nbsp;
                            </Typography>
                            <List>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                Dollar Account Details:<br/>
                                Bank Name: Zenith Bank
                                Account Name: Vivian Ogu Movement Benin City.
                                Account Number: 5366089283
                                Swift Code: ZEIBNGLA
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" align="center" mt={2} style={{color : "white"}}>
                                <small>
                                    &copy; Vivian Ogu Movement 2022
                                </small>
                            </Typography>
                        </Grid>
                    </Grid>

             </Box>


             <Box sx={{padding : 2, display: { xs: 'flex', md: 'none' }}} style={{backgroundColor : "black"}}>
                    <Grid container>
                        <Grid item xs={12}>
                           <h6 align="center">
                           <img 
                                src={require('../../../src/images/circleVivian.png')}
                                style={{width : "200px", height : "200px", marginRight : "10px", float : "center"}}
                            />
                           </h6>
                        
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" mt={1} style={{color : "white", fontWeight : 700}}>
                                Quick links
                            </Typography>
                            <List>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                    <Link to="/Events" style={{textDecoration : 'none', color : 'white'}}>
                                        Events
                                    </Link>
                                </ListItem>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                    <Link to="/Competitions" style={{textDecoration : 'none', color : 'white'}}>
                                        Competitions
                                    </Link>
                                </ListItem>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                    <Link to="/Donate" style={{textDecoration : 'none', color : 'white'}}>
                                        Donate
                                    </Link>
                                </ListItem>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                    <Link to="/About" style={{textDecoration : 'none', color : 'white'}}>
                                        About
                                    </Link>
                                </ListItem>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                    <Link to="/Contact" style={{textDecoration : 'none', color : 'white'}}>
                                        Contact
                                    </Link>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" mt={1} style={{color : "white", fontWeight : 700}}>
                                Quick Donation
                            </Typography>
                            <List>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                Naira Account Details:<br/>
                                Bank Name: Zenith Bank
                                Account Name: Vivian Ogu Movement Benin City.
                                Account Number: 1013793121
                                </ListItem>
                            </List>
                         
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{color : "white", fontWeight : 700}}>
                                &nbsp;
                            </Typography>
                            <List>
                                <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                Dollar Account Details:<br/>
                                Bank Name: Zenith Bank
                                Account Name: Vivian Ogu Movement Benin City.
                                Account Number: 5366089283
                                Swift Code: ZEIBNGLA
                                </ListItem>
                            </List>

                            <Grid container spacing={1}>
                                        <Grid item>
                                        <a href="https://www.instagram.com/accounts/login/?next=/vivianogumovement/" style={{textDecoration : 'none'}}>
                                            <Button variant="contained" >
                                                <Instagram /> 
                                            </Button>
                                        </a>
                                        </Grid>
                                        <Grid item>
                                        <a href="https://www.instagram.com/accounts/login/?next=/vivianogumovement/" style={{textDecoration : 'none'}}>
                                            <Button variant="contained" color="error">
                                                <YouTube /> 
                                            </Button>
                                        </a>
                                        </Grid>
                                        <Grid item>
                                        <a href="https://twitter.com/VO_Movement?t=I24Co1ia3FCBaLhThShL-w&s=09" style={{textDecoration : 'none'}}>
                                            <Button variant="contained">
                                                <Twitter /> 
                                            </Button>
                                        </a>
                                        </Grid>
                                        <Grid item>
                                        <a href="https://www.facebook.com/Vivian-Ogu-Movement-110675584791777/" style={{textDecoration : 'none'}}>
                                            <Button variant="contained">
                                                <Facebook /> 
                                            </Button>
                                        </a>
                                        </Grid>
                                        <Grid item>
                                        <a href="https://api.whatsapp.com/send?phone=2348035643063" style={{textDecoration : 'none'}}>
                                            <Button variant="contained" color="success">
                                                <WhatsApp /> One
                                            </Button>
                                        </a>
                                        </Grid>
                                        <Grid item>
                                        <a href="https://api.whatsapp.com/send?phone=2349155257413" style={{textDecoration : 'none'}}>
                                            <Button variant="contained" color="success">
                                                <WhatsApp /> Two
                                            </Button>
                                        </a>
                                        </Grid>
                                </Grid>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="h6" align="center" mt={2} style={{color : "white"}}>
                        <small>
                            &copy; Vivian Ogu Movement 2022
                        </small>
                    </Typography>
                        </Grid>
                    </Grid>
                    
             </Box>
    
        </>
    )
}

export default Footer;