import React,{useState, useEffect} from "react";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid";
import axios from "axios";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Whatsapp from "@mui/icons-material/WhatsApp"
import Instagram from "@mui/icons-material/Instagram"
import YouTube from "@mui/icons-material/YouTube";
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Footer from "./footer";
import Navigation from "./nav";
import ShowProjectHome from "./showProjectsHome";

const Donate = () => {

    return(
        <>
            <Navigation />
            <Box flexGrow={1} padding={2} sx={{display: { xs: 'none', md: 'flex' }}}>
                <Grid spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h3" color="primary" sx={{fontWeight : 700}}>
                            Donations / Project update
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <List>
                                    <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                        Naira Account Details:<br/>
                                        Bank Name: Zenith Bank
                                        Account Name: Vivian Ogu Movement Benin City.
                                        Account Number: 1013793121
                                    </ListItem>
                                </List>
                                <hr/>
                                <List>
                                    <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                                        Dollar Account Details:<br/>
                                        Bank Name: Zenith Bank
                                        Account Name: Vivian Ogu Movement Benin City.
                                        Account Number: 5366089283
                                        Swift Code: ZEIBNGLA
                                    </ListItem>
                                </List>

                                <Grid container padding={1} spacing={2}>
                                    <Grid container mb={1} spacing="2">
                                        <Grid item xs={6}>
                                            <a href="https://api.whatsapp.com/send?phone=2348035643063" style={{textDecoration : 'none'}}>
                                                <Button fullWidth variant="contained" color="success" sx={{padding : 2}}>
                                                    <Whatsapp />  Line One
                                                </Button>
                                            </a>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <a href="https://api.whatsapp.com/send?phone=2349155257413" style={{textDecoration : 'none'}}>
                                                <Button fullWidth variant="contained" color="success" sx={{padding : 2}}>
                                                    <Whatsapp />  Line Two
                                                </Button>
                                            </a>
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </Grid>

                            <Grid item xs={6}>

                                <ShowProjectHome />

                            </Grid>

                            <Grid item xs={3}>


                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>



                </Box>

                <Box flexGrow={1} padding={2} sx={{display: { xs: 'flex', md: 'none' }}}>
                    <Grid>
                        <Grid item xs={12}>
                        <Typography variant="h3" color="primary" sx={{fontWeight : 700}}>
                                            Donations / Project update
                                        </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Grid container spacing={2}>
                <Grid item xs={12}>
                        <List>
                            <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                            Naira Account Details:<br/>
                            Bank Name: Zenith Bank
                            Account Name: Vivian Ogu Movement Benin City.
                            Account Number: 1013793121
                            </ListItem>
                        </List>
                        <hr/>
                        <List>
                            <ListItem style={{color : "whitesmoke", fontWeight : 700}}>
                            Dollar Account Details:<br/>
                            Bank Name: Zenith Bank
                            Account Name: Vivian Ogu Movement Benin City.
                            Account Number: 5366089283
                            Swift Code: ZEIBNGLA
                            </ListItem>
                        </List>
                
                        <Grid container padding={1} spacing={2}>
                        <Grid container mb={1} spacing="2">
                                <Grid item xs={6}>
                                    <a href="https://api.whatsapp.com/send?phone=2348035643063" style={{textDecoration : 'none'}}>
                                        <Button fullWidth variant="contained" color="success" sx={{padding : 2}}>
                                        <Whatsapp />  Line One
                                        </Button>
                                    </a>
                                </Grid>
                                <Grid item xs={6}>
                                    <a href="https://api.whatsapp.com/send?phone=2349155257413" style={{textDecoration : 'none'}}>
                                        <Button fullWidth variant="contained" color="success" sx={{padding : 2}}>
                                        <Whatsapp />  Line Two
                                        </Button>
                                    </a>
                                </Grid>
                    </Grid>
                
                        </Grid>
                       
                    </Grid>
                   
                    <Grid item xs={12}>
                        
                            <ShowProjectHome />

                    </Grid>

                    <Grid item xs={3}>
                       
                       
                       </Grid>
                </Grid>
                        </Grid>
                    </Grid>
                
               

                </Box>
            <Footer />
        
        </>
    )
}

export default Donate;