import React from "react";
import Navigation from "./components/nav";
import Footer from "./components/footer";
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Whatsapp from "@mui/icons-material/WhatsApp"
import Instagram from "@mui/icons-material/Instagram"
import YouTube from "@mui/icons-material/YouTube";
import Twitter from "@mui/icons-material/Twitter"
import Facebook from "@mui/icons-material/Facebook"

const Contact = () => {

    return (

        <>
            <Navigation />
            <Box flexGrow={1} padding={2} sx={{display: { xs: 'none', md: 'flex' }}}>
                <Grid>
                    <Grid item xs={12}>
                        <Typography variant="h1" color="primary" sx={{fontWeight : 700}}>
                            Contact Us
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} padding={5}>
                            <Grid item xs={4}>
                                <Typography variant="h5" color="white" sx={{fontWeight : 700}}>
                                    Secretariat
                                </Typography>
                                <address style={{color : "white"}}>
                                    Victoria Garden
                                    (Opp. St. Paul Catholic Church)
                                    30 Airport Road, Benin City
                                </address>
                                <phone style={{color : "white"}}>
                                    08035643063
                                </phone>
                                <p style={{color : "white"}}>
                                    info@vivianogu.org
                                </p>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h5" color="white" sx={{fontWeight : 700}}>
                                    VOMAC
                                </Typography>
                                <address style={{color : "white"}}>
                                    No 1 Vivian Ogu Street
                                    Off Ehimare Street
                                    Off Benin-Sapele Road
                                    Benin City
                                </address>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h5" color="white" sx={{fontWeight : 700}}>
                                    Send us a message
                                </Typography>
                                <Grid container mb={1} spacing="2">
                                    <Grid item xs={6}>
                                        <a href="https://api.whatsapp.com/send?phone=2348035643063" style={{textDecoration : 'none'}}>
                                            <Button fullWidth variant="contained" color="success" sx={{padding : 2}}>
                                                <Whatsapp />  Line One
                                            </Button>
                                        </a>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <a href="https://api.whatsapp.com/send?phone=2349155257413" style={{textDecoration : 'none'}}>
                                            <Button fullWidth variant="contained" color="success" sx={{padding : 2}}>
                                                <Whatsapp />  Line Two
                                            </Button>
                                        </a>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <a href="https://www.instagram.com/accounts/login/?next=/vivianogumovement/" style={{textDecoration : 'none'}}>
                                            <Button variant="contained" >
                                                <Instagram /> Instagram
                                            </Button>
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <a href="https://www.instagram.com/accounts/login/?next=/vivianogumovement/" style={{textDecoration : 'none'}}>
                                            <Button variant="contained" color="error">
                                                <YouTube /> YouTube
                                            </Button>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>



            </Box>
            <Box flexGrow={1} padding={2} sx={{display: { xs: 'flex', md: 'none' }}}>

                <Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3" color="primary" sx={{fontWeight : 700}}>
                            Contact Us
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} padding={1}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="white" sx={{fontWeight : 700}}>
                                    Secretariat
                                </Typography>
                                <address style={{color : "white"}}>
                                    Victoria Garden
                                    (Opp. St. Paul Catholic Church)
                                    30 Airport Road, Benin City
                                </address>
                                <phone style={{color : "white"}}>
                                    08035643063
                                </phone>
                                <p style={{color : "white"}}>
                                    info@vivianogu.org
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="white" sx={{fontWeight : 700}}>
                                    VOMAC
                                </Typography>
                                <address style={{color : "white"}}>
                                    No 1 Vivian Ogu Street
                                    Off Ehimare Street
                                    Off Benin-Sapele Road
                                    Benin City
                                </address>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="white" sx={{fontWeight : 700}}>
                                    Send us a message
                                </Typography>
                                <Grid container mb={1} spacing="2">
                                    <Grid item xs={6}>
                                        <a href="https://api.whatsapp.com/send?phone=2348035643063" style={{textDecoration : 'none'}}>
                                            <Button fullWidth variant="contained" color="success" sx={{padding : 2}}>
                                                <Whatsapp />  Care Line One
                                            </Button>
                                        </a>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <a href="https://api.whatsapp.com/send?phone=2349155257413" style={{textDecoration : 'none'}}>
                                            <Button fullWidth variant="contained" color="success" sx={{padding : 2}}>
                                                <Whatsapp />  Care Line Two
                                            </Button>
                                        </a>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <a href="https://www.instagram.com/accounts/login/?next=/vivianogumovement/" style={{textDecoration : 'none'}}>
                                            <Button variant="contained" >
                                                <Instagram />
                                            </Button>
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <a href="https://www.instagram.com/accounts/login/?next=/vivianogumovement/" style={{textDecoration : 'none'}}>
                                            <Button variant="contained" color="error">
                                                <YouTube />
                                            </Button>
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <a href="https://twitter.com/VO_Movement?t=I24Co1ia3FCBaLhThShL-w&s=09" style={{textDecoration : 'none'}}>
                                            <Button variant="contained">
                                                <Twitter />
                                            </Button>
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <a href="https://www.facebook.com/Vivian-Ogu-Movement-110675584791777/" style={{textDecoration : 'none'}}>
                                            <Button variant="contained">
                                                <Facebook />
                                            </Button>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Footer />

        </>
    )
}

export default Contact;