import React,{useState} from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Navigate, useNavigate } from 'react-router-dom';
import AutoSlider from "./adminSlider"

const pages = ['Home', 'Events', 'Competitions', 'Project', 'Testimonies', 'Gallery', 'Updates', 'Logout'];

const AdminNav = () => {

    let navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    //use a switch and case statement to navigate pages
    switch (page) {
        case "Home":
            navigate('/admin/dashboard')
            break;
        case "Events":
            navigate('/admin/events')
            break;
        case "Competitions":
            navigate('/admin/Competitions')
            break;
        case "Project":
            navigate('/admin/donate')
            break;
        case "Testimonies":
            navigate('/admin/Testimonies')
            break;
        case "Gallery":
            navigate('/admin/Gallery')
            break;
        case "Updates":
            navigate('/admin/Updates')
            break;
        case "Logout":
            localStorage.clear()
            navigate('/')
            break;
        default:
            break;
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

    return (
        <>
            <AppBar position="fixed">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                    <img 
                        src={require('../../../src/images/circleVivian.png')}
                        style={{width : "50px", height : "50px", marginRight : "10px"}}
                    />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        flexGrow: 1,
                        fontWeight: 700,
                        color: 'inherit',
                        textDecoration: 'none',
                        }}
                    >
                        VOM Admin Dashboard
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                        >
                        <MenuIcon />
                        </IconButton>
                        <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                        >
                        {pages.map((page) => 
                            <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                            <Typography textAlign="center" sx={{fontWeight : 700}}>{page}</Typography>
                            </MenuItem>
                        )}
                        </Menu>
                    </Box>

                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                        mr: 2,
                        display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        color: 'inherit',
                        textDecoration: 'none',
                        }}
                    >
                        VOM Admin Dashboard
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                        <Button
                            key={page}
                            onClick={() => handleCloseNavMenu(page)}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            {page}
                        </Button>
                        ))}
                    </Box>

                    </Toolbar>
                </Container>
                </AppBar> 
                <AutoSlider />
        </>
    )
}

export default AdminNav;