import React,{useState, useEffect} from "react";
import Navigation from "./components/nav";
import HomeBody from "./components/homebody";
import Box from "@mui/material/Box"
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button"
import Footer from "./components/footer";
import YouTube from "@mui/icons-material/YouTube"
import Container from '@mui/material/Container';
import HomePageEvents from "./components/homePageEvents";
import { Link } from 'react-router-dom';
import axios from "axios";
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";

const ItemOne = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(4),
    textAlign: 'left',
    marginBottom : 5,
    color: theme.palette.text.secondary,
}));


const HomeUpdates = () => {

    useEffect(() => {
        getPost()
    });

    //create state for post fetched
    const [Gpost, setGottenPost] = useState([])


    const getPost = async() => {

        await axios.get('api/users/getUpdates').
        then(res => {
            setGottenPost(res.data);
        }).catch(err => {
            // alert(err);
        })
    }


    return (
        <>
            <Navigation />
            <Box flexGrow={1} padding={2} sx={{display: { xs: 'none', md: 'flex' }}}>
                <Grid spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h3" color="primary" sx={{fontWeight : 700}}>
                            Recent Updates
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            Gpost.map(post => <ItemOne key={post._d}>
                                <Typography variant="h5" sx={{fontWeight : 500}}>
                                    {post.title}
                                </Typography>
                                <Typography variant="body1" sx={{fontWeight : 200}}>
                                    {post.body}
                                </Typography>
                            </ItemOne>)
                        }
                    </Grid>
                </Grid>
            </Box>

            <Box flexGrow={1} padding={2} sx={{display: { xs: 'flex', md: 'none' }}}>
                <Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3" color="primary" sx={{fontWeight : 700}}>
                            Recent Updates
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            Gpost.map(post => <ItemOne key={post._d}>
                                <Typography variant="h5" sx={{fontWeight : 500}}>
                                    {post.title}
                                </Typography>
                                <Typography variant="body1" sx={{fontWeight : 200}}>
                                    {post.body}
                                </Typography>

                            </ItemOne>)
                        }
                    </Grid>
                </Grid>



            </Box>
            <Footer />
        </>
    )
}

export default HomeUpdates;