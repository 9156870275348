import React,{useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import AdminNav from "./components/adminNav";
import PullUpdates from "./components/pullUpdates";
import Home from "./home";


const ItemOne = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(4),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const Updates = () => {

    //create state for post
    const [post, setPost] = useState({
        title : '',
        body : ''
    });

    const handleSubmit = async(e) => {
        e.preventDefault();
        await axios.post('api/users/newUpdate', post).then(
            res => {
                alert(res.data)
                setPost({
                    title : '',
                    body : ''
                })
            }
        ).catch(err => {
            alert(err);
        })
    }

    //check to see if user is authenticated
    const check = localStorage.getItem('Auth')
    console.log(check)

    
        return (
            check == null ? 
                <Home />
            :
                check == "true" ?
                    <>
                    <AdminNav />
                    <Box flexGrow={1} padding={2} sx={{display: { xs: 'none', md: 'flex' }}}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography variant="h4" sx={{fontWeight : 200, color : "white"}}>
                                    Post new update
                                </Typography>
                                <ItemOne>
                                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                        <TextField onChange={(e) =>  setPost((post) => ({...post, title : e.target.value}))} variant="outlined" fullWidth label="Title" type="text"  value={post.title} />
                                        <TextField onChange={(e) =>  setPost((post) => ({...post, body : e.target.value}))} variant="outlined" multiline maxRows={10} fullWidth label="Body" type="text" sx={{marginTop : 1}} value={post.body} />
                                        <Button type="submit" color="primary" variant="contained" sx={{marginTop : 1}}>
                                            Upload to page
                                        </Button>
                                    </form>
                                </ItemOne>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="h4" sx={{fontWeight : 200, color : "white"}}>
                                    Recent updates
                                </Typography>
                                <PullUpdates />
                            </Grid>
                        </Grid>
                    </Box>
        
                    <Box flexGrow={1} padding={2} sx={{display: { xs: 'flex', md: 'none' }}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4" sx={{fontWeight : 200, color : "white"}}>
                                    Post new update
                                </Typography>
                                <ItemOne>
                                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                        <TextField onChange={(e) =>  setPost((post) => ({...post, title : e.target.value}))} variant="outlined" fullWidth label="Title" type="text"  value={post.title} />
                                        <TextField onChange={(e) =>  setPost((post) => ({...post, body : e.target.value}))} variant="outlined" multiline maxRows={10} fullWidth label="Body" type="text" sx={{marginTop : 1}} value={post.body} />
                                        <Button type="submit" color="primary" variant="contained" sx={{marginTop : 1}}>
                                            Upload to page
                                        </Button>
                                    </form>
                                </ItemOne>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" sx={{fontWeight : 200, color : "white"}}>
                                    Recent updates
                                </Typography>
                                <PullUpdates />
                            </Grid>
                        </Grid>
                    </Box>
                    </>
                :
                    <Home />
        )
}

export default Updates;