import React from "react";
import AdminDonateBody from "./components/adminDonateBody";
import AdminNav from "./components/adminNav"
import Home from "./home";


const AdminDonate = () => {


    //check to see if user is authenticated
    const check = localStorage.getItem('Auth')
    console.log(check)

    
        return (
            check == null ? 
                <Home />
            :
                check == "true" ?
                    <>
                        <AdminNav />
                        <AdminDonateBody />
                    </>
                :
                    <Home />
        )
}

export default AdminDonate;