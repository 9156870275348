import React,{useState, useEffect} from "react";
import axios from "axios";
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"


const ShowCompetitionAdmin = () => {

    useEffect(() => {
        getAllCompetitions()
    }, [])

    const [competitions, setCompetition] = useState([]);

    const getAllCompetitions = async() => {
        await axios.get('api/users/getAllCompetitions/').then(
            res => {
                setCompetition(res.data)
               
            }
        ).catch(err => {
            console.log(err);
        })
    }

    const handleDelete = async(e, _id) => {
       
        await axios.delete('api/users/deleteCompetition/'+_id).then(
            res => {
                alert(res.data)
                getAllCompetitions();
            }
        ).catch(err => {
            console.log(err)
        })
    }

    return (
        <>

        <Box padding={2} sx={{display: { xs: 'none', md: 'flex' }}}>
           
        {
                competitions.map(competition => 
                <Card key={competition._id} sx={{ maxWidth: "100%", marginBottom : 1 }}>
                    <CardMedia component="img"  image={competition.image} alt={competition.title}/>          
                            <Grid container stacing={2}>
                                <Grid item xs={4}>
                                    <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                        {competition.title} 
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                        Start Date : {competition.start} 
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                        End Date : {competition.end} 
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography gutterBottom variant="body1" padding={1} sx={{marginBottom : 0}} component="div">
                                {competition.about}
                            </Typography>


                            <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                        Application channel 
                                    </Typography>
                            <Typography gutterBottom variant="body1" padding={1} sx={{marginBottom : 0}} component="div">
                                {competition.channel}
                            </Typography>

                            <Typography gutterBottom variant="body1" color="error" padding={1} sx={{marginBottom : 0, fontWeight : 700}} component="div">
                                Application Deadline : {competition.deadline}
                            </Typography>
                           
                            <Button variant="contained" color="error" sx={{margin : 1}} onClick={(e) => handleDelete(e, competition._id)}>
                                Delete Competition
                            </Button>
                </Card>)
            }

        </Box>


        <Box padding={2} sx={{display: { xs: 'flex', md: 'none' }}}>
           
        {
                competitions.map(competition => 
                <Card key={competition._id} sx={{ maxWidth: "100%", marginBottom : 1 }}>
                    <CardMedia component="img"  image={competition.image} alt={competition.title}/>          
                            <Grid container stacing={2}>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                        {competition.title} 
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                        Start Date : {competition.start} 
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                        End Date : {competition.end} 
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography gutterBottom variant="body1" padding={1} sx={{marginBottom : 0}} component="div">
                                {competition.about}
                            </Typography>


                            <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                        Application channel 
                                    </Typography>
                            <Typography gutterBottom variant="body1" padding={1} sx={{marginBottom : 0}} component="div">
                                {competition.channel}
                            </Typography>

                            <Typography gutterBottom variant="body1" color="error" padding={1} sx={{marginBottom : 0, fontWeight : 700}} component="div">
                                Application Deadline : {competition.deadline}
                            </Typography>
                           
                            <Button variant="contained" color="error" sx={{margin : 1}} onClick={(e) => handleDelete(e, competition._id)}>
                                Delete Competition
                            </Button>
                </Card>)
            }

        </Box>
           
        </>
    )
}

export default ShowCompetitionAdmin;