import React,{useState, useEffect} from "react";
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button"
import YouTube from "@mui/icons-material/YouTube"
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import axios from "axios";
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";

const PullGallery = () => {

    useEffect(() => {
        getAllEvents()
    },[])

    const [events, setEvents] = useState([]);

    const getAllEvents = async() => {
        await axios.get('api/users/getAllPictures').then(
            res => {
                setEvents(res.data)
            }
        ).catch(err => {
            console.log(err);
        })
    }

    return(
        <>
            <Box sx={{padding : 1, display: { xs: 'none', md: 'flex' }}}>
                <Grid spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h1" color="primary" sx={{fontWeight : 700}}>
                            Gallery
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {
                                events.map(bevent =>
                                    <Grid item xs={4} key={bevent._id} sx={{ maxWidth: "100%" }}>
                                        <Card >
                                            <CardMedia component="img" image={bevent.image} alt={bevent.title}/>
                                            <Typography gutterBottom variant="body2" padding={1} sx={{marginBottom : 0}} component="div">
                                                {bevent.title}
                                            </Typography>
                                        </Card>
                                    </Grid>)
                            }
                        </Grid>
                    </Grid>
                </Grid>

            </Box>

            <Box sx={{padding : 1, display: { xs: 'flex', md: 'none' }}}>
                <Grid>
                    <Grid item xs={12}>
                        <Typography variant="h1" color="primary" sx={{fontWeight : 700}}>
                            Gallery
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {
                                events.map(bevent =>
                                    <Grid item xs={12} key={bevent._id} sx={{ maxWidth: "100%", marginBottom : 1 }}>
                                        <Card >
                                            <CardMedia component="img" image={bevent.image} alt={bevent.title}/>
                                            <Typography gutterBottom variant="body2" padding={1} sx={{marginBottom : 0}} component="div">
                                                {bevent.title}
                                            </Typography>
                                        </Card>
                                    </Grid>)
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default PullGallery;