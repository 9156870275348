import React,{useState, useEffect} from "react";
import axios from "axios";
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button"

const ShowProjectAdmin = () => {


    useEffect(() => {
        getAllEvents()
    },[])

    const [events, setEvents] = useState([]);

    const getAllEvents = async() => {
        await axios.get('api/users/getAllProjects').then(
            res => {
                setEvents(res.data)
            }
        ).catch(err => {
            console.log(err);
        })
    }

    const handleDelete = async(e, _id) => {
       
        await axios.delete('api/users/deleteProject/'+_id).then(
            res => {
                alert(res.data)
                getAllEvents();
            }
        ).catch(err => {
            console.log(err)
        })
    }


    return(
        <>
            {
                events.map(bevent => 
                <Card key={bevent._id} sx={{ maxWidth: "100%", marginBottom : 1 }}>
                    <CardMedia component="img" image={bevent.image} alt={bevent.title}/>          
                            <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                {bevent.title}
                            </Typography>
                            <Typography variant="body2" padding={1} color="text.secondary">
                                {bevent.body}
                            </Typography>
                            <Button variant="contained" color="error" sx={{margin : 1}} onClick={(e) => handleDelete(e, bevent._id)}>
                                Delete Event
                            </Button>
                </Card>)
            }
        </>
    )
}

export default ShowProjectAdmin;