import React from "react";
import Navigation from "./components/nav";
import Footer from "./components/footer";
import AboutBody from "./components/aboutBody";

const About = () => {

    return (
        <>
            <Navigation />
            <AboutBody />
            <Footer/>
        </>
    )
}

export default About;