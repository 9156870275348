import React,{useState, useEffect} from "react";
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import axios from "axios";
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';



const HomeBody = () => {

    const ItemOne = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(4),
        textAlign: 'left',
        color: theme.palette.text.secondary,
      }));

        useEffect(() => {
            getPost()
        });
    
        //create state for post fetched 
        const [Gpost, setGottenPost] = useState([])
    
    
        const getPost = async() => {
            
            await axios.get('api/users/getPost/').
            then(res => {
                    setGottenPost(res.data);
                }).catch(err => {
                // alert(err);
            })
        }

    return (
        <>
          <Container maxWidth="xl">
          <Box sx={{display: { xs: 'none', md: 'flex' }}}>
                    <Box sx={{flexGrow: 1, padding: 1}}>
                    <Typography variant="h1" color="primary" sx={{fontWeight : 700}}>
                                            Join the movement
                                        </Typography>
                        <Grid container spacing={2}>
                                <Grid item xs={8}>
                                <ItemOne >

                                {
                                    Gpost.map(post =>
                                            <div key={post._d} style={{marginBottom : "30px"}}>
                                                <Typography variant="h4" sx={{fontWeight : 500}} >
                                                {post.title}
                                            </Typography>
                                            <Typography variant="body1" sx={{fontWeight : 200}}>
                                                {post.body}
                                            </Typography>
                                            </div>
                                        )
                                }
                                </ItemOne>

                                </Grid>
                                <Grid item xs={4}>

                                    <Card sx={{ maxWidth: "100%" }}>
                                        <CardMedia
                                            component="img"
                                            height="250"
                                            image={require('../../../src/images/vivian.jpg')}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                            Vivian Ogu
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                            Vivian Uchechi Ogu was a 14-year-old girl who lived an exemplary holy life through her missionary works
        and died a heroic death by opting to be killed rather than being sexually defiled.
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                </Grid>
                        </Grid>
                    </Box>
                  
           </Box>

           <Box sx={{display: { xs: 'flex', md: 'none' }}}>
                    <Box sx={{flexGrow: 1, marginBottom : 1}}>
                    <Typography variant="h3" color="primary" sx={{fontWeight : 700}}>
                                            Join the movement
                                        </Typography>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>

                                <Card sx={{ maxWidth: "100%" }}>
                                    <CardMedia
                                        component="img"
                                        height="250"
                                        image={require('../../../src/images/vivian.jpg')}
                                        alt="Vivian Ogu"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                        Vivian Ogu
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                        Vivian Uchechi Ogu was a 14-year-old girl who lived an exemplary holy life through her missionary works
    and died a heroic death by opting to be killed rather than being sexually defiled.
                                        </Typography>
                                    </CardContent>
                                </Card>

                            </Grid>
                                <Grid item xs={12}>
                                <ItemOne >

                                {
                                    Gpost.map(post =>
                                            <div key={post._d} style={{marginBottom : "30px"}}>
                                                <Typography variant="h4" sx={{fontWeight : 500}} >
                                                {post.title}
                                            </Typography>
                                            <Typography variant="body1" sx={{fontWeight : 500}}>
                                                {post.body}
                                            </Typography>
                                            </div>
                                        )
                                }
                                </ItemOne>

                                </Grid>

                        </Grid>
                    </Box>
                  
           </Box>
          </Container>
        </>
    )
}
export default HomeBody;