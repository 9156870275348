import React,{useState} from "react";
import AdminNav from "./components/adminNav"
import HomePageEdit from "./components/homePageEdit"
import { useNavigate } from "react-router";
import Home from "./home";


const Dashboard = () => {

    //check to see if user is authenticated
    const check = localStorage.getItem('Auth')
    console.log(check)

    
        return (
            check == null ? 
                <Home />
            :
                check == "true" ?
                    <>
                        <AdminNav />
                        <HomePageEdit/>
                    </>
                :
                    <Home />
        )
    
}

export default Dashboard;