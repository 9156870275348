import React from "react";
import Navigation from "./components/nav";
import Footer from "./components/footer";
import LoginForm from "./components/loginForm"

const Login = () => {

    return (
        <>
            <Navigation />
            <LoginForm />
            <Footer />
        </>
    )
}

export default Login;