import React from "react";
import Footer from "./components/footer";
import Navigation from "./components/nav";
import RegForm from "./components/registrationForm";

const Register = () => {

    return (
        <>
            <Navigation/>
            <RegForm />
            <Footer />
        </>
    )
}

export default Register;