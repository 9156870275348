import React,{useState, useEffect} from "react";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid";
import axios from "axios";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ShowProjectAdmin from "./showProjectAdmin";
import AutoSlider from "./adminSlider"

const ItemOne = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(4),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    }));

const AdminDonateBody = () => {



    useEffect(() => {
        donatingAmount()
    }, [])


    const [event, setEvent] = useState({
        title : '', 
        body : ''
    })
    const {title, body} = event;
    const [image, setOnChangeImage] = useState();
  
    const formData = new FormData();
    //append all properties
    formData.append('title', title);
    formData.append('body', body);
    formData.append('image', image);

    const config = {
        headers : {
            'Content-Type' : 'multipart/form-data',
        }
    }
    //handle form submission
    const handleSubmit = async(e) => {
        e.preventDefault();
       await axios.post('api/users/uploadProject', formData, config).
        then( function (response) {

            alert("Project update published successfully");
            
        }).catch(err => {
            console.log(err)
        })
    }

    const One = 1;
    const Ten = 10;
    const Hundred = 100;
    const Thousand = 1000;


        //state for donating amount
        const[donate, setDonating] = useState({
            "amount" : 0,
            "target" : 0
        });
    

    //pull
    const donatingAmount = async() => {
        await axios.get('api/users/getDonating').then(res => {
            setDonating(res.data)
        })
    }


    const { amount, target } = donate;
    //initialize formData
    const donateData = new FormData();
    //append all properties of new vendor
    donateData.append('amount', amount);
    donateData.append('target', target)

     //add percent 1000
     const  handleDonateFive = async(e, One) => {

        const newDonating = donate.amount + One;
       
        setDonating((donate) => ({...donate, amount : newDonating}))
        await axios.put('api/users/updateDonatingFive', donate).then(res => {
            // alert(res.data)
        })
    }

    //add percent 1000
    const  handleDonateOnek = async(e, Ten) => {

        const newDonating = donate.amount + Ten;
       
        setDonating((donate) => ({...donate, amount : newDonating}))
        await axios.put('api/users/updateDonatingOnek', donate).then(res => {
            // alert(res.data)
        })
    }

    //add percent 10000
    const  handleDonateTenk = async(e, Hundred) => {

        const newDonating = donate.amount + Hundred;
       
        setDonating((donate) => ({...donate, amount : newDonating}))
        await axios.put('api/users/updateDonatingTenk', donate).then(res => {
            // alert(res.data)
        })
    }

    //add percent 10000
    const  handleDonateFiftyk = async(e, Thousand) => {

        const newDonating = donate.amount + Thousand;
       
        setDonating((donate) => ({...donate, amount : newDonating}))
        await axios.put('api/users/updateDonatingFiftyk', donate).then(res => {
            // alert(res.data)
        })
    }




       //add percent 1000
       const  handleDonateFiveRemove = async(e, One) => {

        const newDonating = donate.amount - One;
       
        setDonating((donate) => ({...donate, amount : newDonating}))
        await axios.put('api/users/updateDonatingFiveRemove', donate).then(res => {
            // alert(res.data)
        })
    }

    //add percent 1000
    const  handleDonateOnekRemove = async(e, Ten) => {

        const newDonating = donate.amount - Ten;
       
        setDonating((donate) => ({...donate, amount : newDonating}))
        await axios.put('api/users/updateDonatingOnekRemove', donate).then(res => {
            // alert(res.data)
        })
    }

    //Remove percent 10000
    const  handleDonateTenkRemove = async(e, Hundred) => {

        const newDonating = donate.amount - Hundred;
       
        setDonating((donate) => ({...donate, amount : newDonating}))
        await axios.put('api/users/updateDonatingTenkRemove', donate).then(res => {
            // alert(res.data)
        })
    }

    //add percent 10000
    const  handleDonateFiftykRemove = async(e, Thousand) => {

        const newDonating = donate.amount - Thousand;
       
        setDonating((donate) => ({...donate, amount : newDonating}))
        await axios.put('api/users/updateDonatingFiftykRemove', donate).then(res => {
            // alert(res.data)
        })
    }


    return(

        <>
             <Box flexGrow={1} padding={1} sx={{display: { xs: 'none', md: 'flex' }}}>
                <Grid container spacing={2}>
                    
                    <Grid item xs={4}>
                        <ItemOne>
                            <Typography variant="h5" align="center" sx={{fontWeight : 500}}>
                                Donations Panel
                            </Typography>

                            <Typography variant="h3" align="center" sx={{fontWeight : 500}}>
                                {donate.amount}
                            </Typography>
                            <h6 align="center" style={{margin : "0px", padding : "0px"}}><small style={{color : "blue", fontWeight : 400, fontSize : "1.1em"}}>Bricks recieved so far</small></h6>
                            <hr/>
                            <Typography variant="h5" align="center" sx={{fontWeight : 500}}>
                                Add a donation
                            </Typography>
                            <Button variant="contained" color="primary" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateFive(e, One)}>
                                1 Brick
                            </Button>
                            <Button variant="contained" color="primary" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateOnek(e, Ten)}>
                                10 Bricks
                            </Button>
                            <Button variant="contained"  color="primary" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateTenk(e, Hundred)}>
                                100 Bricks
                            </Button>
                            <Button variant="contained"  color="primary" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateFiftyk(e, Thousand)}>
                                1000 bricks
                            </Button>
                            <hr/>

                            <Typography variant="h5" align="center" sx={{fontWeight : 500}}>
                                Remove a donation
                            </Typography>
                            <Button variant="contained" color="error" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateFiveRemove(e, One)}>
                                1 Brick
                            </Button>
                            <Button variant="contained" color="error" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateOnekRemove(e, Ten)}>
                                10 Bricks
                            </Button>
                            <Button variant="contained"  color="error" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateTenkRemove(e, Hundred)}>
                                100 Bricks
                            </Button>
                            <Button variant="contained"  color="error" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateFiftykRemove(e, Thousand)}>
                                1000 bricks
                            </Button>


                        
                        </ItemOne>

                         <Typography variant="h4" sx={{fontWeight : 200, color : "white"}}>
                                Add New Project Update
                        </Typography>
                            <ItemOne>
                                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <TextField onChange={(e) =>  setEvent((event) => ({...event, title : e.target.value}))} variant="outlined" fullWidth label="Title of update" type="text" name="title"  value={event.title} />
                                    <TextField onChange={(e) =>  setEvent((event) => ({...event, body : e.target.value}))} variant="outlined" multiline maxRows={10} fullWidth label="Body" name="body" type="text" sx={{marginTop : 1}} value={event.body} />
                                    <TextField type="file" onChange={(e) =>  setOnChangeImage(e.target.files[0])} sx={{marginBottom : 1, marginTop : 1}} fullWidth name="image" />
                                    <Button type="submit" color="primary" variant="contained" sx={{marginTop : 1}}>
                                        Upload update
                                    </Button>
                                </form>
                            </ItemOne>

                    </Grid>

                    <Grid item xs={6}>
                    <ShowProjectAdmin />
                    </Grid>
                    <Grid item xs={2}>
                       
                    </Grid>
                    
                </Grid>
            </Box>

            <Box flexGrow={1} padding={1} sx={{display: { xs: 'flex', md: 'none' }}}>
                <Grid container spacing={2}>
                    
                    <Grid item xs={12}>
                        <ItemOne>
                            <Typography variant="h5" align="center" sx={{fontWeight : 500}}>
                                Donations Panel
                            </Typography>

                            <Typography variant="h3" align="center" sx={{fontWeight : 500}}>
                                {donate.amount}
                            </Typography>
                            <h6 align="center" style={{margin : "0px", padding : "0px"}}><small style={{color : "blue", fontWeight : 400, fontSize : "1.1em"}}>Bricks recieved so far</small></h6>
                            <hr/>
                            <Typography variant="h5" align="center" sx={{fontWeight : 500}}>
                                Add a donation
                            </Typography>
                            <Button variant="contained" color="primary" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateFive(e, One)}>
                                1 Brick
                            </Button>
                            <Button variant="contained" color="primary" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateOnek(e, Ten)}>
                                10 Bricks
                            </Button>
                            <Button variant="contained"  color="primary" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateTenk(e, Hundred)}>
                                100 Bricks
                            </Button>
                            <Button variant="contained"  color="primary" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateFiftyk(e, Thousand)}>
                                1000 bricks
                            </Button>

                            <hr/>

                            <Typography variant="h5" align="center" sx={{fontWeight : 500}}>
                                Remove a donation
                            </Typography>
                            <Button variant="contained" color="error" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateFiveRemove(e, One)}>
                                1 Brick
                            </Button>
                            <Button variant="contained" color="error" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateOnekRemove(e, Ten)}>
                                10 Bricks
                            </Button>
                            <Button variant="contained"  color="error" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateTenkRemove(e, Hundred)}>
                                100 Bricks
                            </Button>
                            <Button variant="contained"  color="error" sx={{fontWeight : 700, marginRight : 1, marginBottom : 1}} onClick={(e) => handleDonateFiftykRemove(e, Thousand)}>
                                1000 bricks
                            </Button>



                        </ItemOne>

                         <Typography variant="h4" sx={{fontWeight : 200, color : "white"}}>
                                Add New Project Update
                        </Typography>
                            <ItemOne>
                                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <TextField onChange={(e) =>  setEvent((event) => ({...event, title : e.target.value}))} variant="outlined" fullWidth label="Title of update" type="text" name="title"  value={event.title} />
                                    <TextField onChange={(e) =>  setEvent((event) => ({...event, body : e.target.value}))} variant="outlined" multiline maxRows={10} fullWidth label="Body" name="body" type="text" sx={{marginTop : 1}} value={event.body} />
                                    <TextField type="file" onChange={(e) =>  setOnChangeImage(e.target.files[0])} sx={{marginBottom : 1, marginTop : 1}} fullWidth name="image" />
                                    <Button type="submit" color="primary" variant="contained" sx={{marginTop : 1}}>
                                        Upload update
                                    </Button>
                                </form>
                            </ItemOne>

                    </Grid>

                    <Grid item xs={12}>
                    <ShowProjectAdmin />
                    </Grid>
                   
                    
                </Grid>
            </Box>
        </>
    )
}

export default AdminDonateBody;