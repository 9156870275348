import React,{useState, useEffect} from "react";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid";
import axios from "axios";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Navigation from "./components/nav";
import Footer from "./components/footer"
import Card from "@mui/material/Card"
import CardMedia  from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

const Events = () => {


    useEffect(() => {
        getAllEvents()
    })

    const [events, setEvents] = useState([]);

    const getAllEvents = async() => {
        await axios.get('api/users/getAllEvents').then(
            res => {
                // console.log(res.data)
                setEvents(res.data)
            }
        ).catch(err => {
            console.log(err);
        })
    }


    return (
        <>
        <Navigation />
        <Box padding={2} sx={{padding : 10, display: { xs: 'none', md: 'flex' }}}>
            <Grid spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h1" color="primary" sx={{fontWeight : 700}}>
                        New Events
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>

                        <Grid item xs={4}>
                            <Card sx={{ maxWidth: "100%" }}>
                                <CardMedia
                                    component="img"
                                    height="250"
                                    image={require('../../src/images/vivian.jpg')}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Vivian Ogu
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Vivian Uchechi Ogu was a 14-year-old girl who lived an exemplary holy life through her missionary works
                                        and died a heroic death by opting to be killed rather than being sexually defiled.
                                    </Typography>
                                </CardContent>
                            </Card>

                        </Grid>



                        <Grid item xs={6}>

                            {
                                events.map(bevent =>
                                    <Card key={bevent._id} sx={{ maxWidth: "100%", marginBottom : 1 }}>
                                        <CardMedia component="img" image={bevent.image} alt={bevent.title}/>
                                        <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                            {bevent.title}
                                        </Typography>
                                        <Typography variant="body2" padding={1} color="text.secondary">
                                            {bevent.body}
                                        </Typography>
                                    </Card>)
                            }

                        </Grid>

                        <Grid item xs={3}>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            </Box>

            <Box padding={2} sx={{padding : 3, display: { xs: 'flex', md: 'none' }}}>
                <Grid>
                    <Grid item xs={12}>
                    <Typography variant="h3" color="primary" sx={{fontWeight : 700}}>
                                            New Events
                                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                    <Grid container spacing={2}>
    <Grid item xs={12}>

    {
events.map(bevent => 
<Card key={bevent._id} sx={{ maxWidth: "100%", marginBottom : 1 }}>
    <CardMedia component="img" image={bevent.image} alt={bevent.title}/>          
            <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                {bevent.title}
            </Typography>
            <Typography variant="body2" padding={1} color="text.secondary">
                {bevent.body}
            </Typography>
</Card>)
}
        
    </Grid>

    <Grid item xs={12}>
    <Card sx={{ maxWidth: "100%" }}>
                        <CardMedia
                            component="img"
                            height="250"
                            image={require('../../src/images/vivian.jpg')}
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Vivian Ogu
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Vivian Uchechi Ogu was a 14-year-old girl who lived an exemplary holy life through her missionary works
and died a heroic death by opting to be killed rather than being sexually defiled.
                            </Typography>
                        </CardContent>
                    </Card>
            
    </Grid>
 

</Grid>
                    </Grid>
                </Grid>
 
              
            </Box>
        <Footer />
        </>
    )
}

export default Events;