import React,{useState, useEffect} from "react";
import axios from "axios";
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const ShowGalleryAdmin = () => {

    useEffect(() => {
        getAllEvents()
    },[])

    const [events, setEvents] = useState([]);

    const getAllEvents = async() => {
        await axios.get('api/users/getAllPictures').then(
            res => {
                setEvents(res.data)
            }
        ).catch(err => {
            console.log(err);
        })
    }

    const handleDelete = async(e, _id) => {

        await axios.delete('api/users/deletePicture/'+_id).then(
            res => {
                alert(res.data)
                getAllEvents();
            }
        ).catch(err => {
            console.log(err)
        })
    }

    return (
        <>

            <Grid container spacing={2} sx={{display: { xs: 'none', md: 'flex' }}}>
                {
                    events.map(bevent =>
                        <Grid item xs={4} key={bevent._id} sx={{ maxWidth: "100%", marginBottom : 1 }}>
                        <Card >
                            <CardMedia component="img" image={bevent.image} alt={bevent.title}/>
                            <Typography gutterBottom variant="body2" padding={1} sx={{marginBottom : 0}} component="div">
                                {bevent.title}
                            </Typography>
                            <Button variant="contained" color="error" sx={{margin : 1}} onClick={(e) => handleDelete(e, bevent._id)}>
                                Delete
                            </Button>
                        </Card>
                            </Grid>)
                }
            </Grid>

            <Grid container spacing={2} sx={{display: { xs: 'flex', md: 'none' }}}>
                {
                    events.map(bevent =>
                        <Grid item xs={12} key={bevent._id} sx={{ maxWidth: "100%", marginBottom : 1 }}>
                        <Card >
                            <CardMedia component="img" image={bevent.image} alt={bevent.title}/>
                            <Typography gutterBottom variant="body2" padding={1} sx={{marginBottom : 0}} component="div">
                                {bevent.title}
                            </Typography>
                            <Button variant="contained" color="error" sx={{margin : 1}} onClick={(e) => handleDelete(e, bevent._id)}>
                                Delete
                            </Button>
                        </Card>
                            </Grid>)
                }
            </Grid>

        </>
    )
}

export default ShowGalleryAdmin;