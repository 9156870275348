import React,{useState, useEffect} from "react";
import Navigation from "./components/nav";
import HomeBody from "./components/homebody";
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button"
import Footer from "./components/footer";
import YouTube from "@mui/icons-material/YouTube"
import Container from '@mui/material/Container';
import HomePageEvents from "./components/homePageEvents";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import axios from "axios";
import CardMedia from '@mui/material/CardMedia';


const Home = () => {

    useEffect(() => {
        getPost()
    });


    //create state for post fetched
    const [Gpost, setGottenPost] = useState([])


    const getPost = async() => {

        await axios.get('api/users/getCreed/').
        then(res => {
            setGottenPost(res.data);
        }).catch(err => {
            // alert(err);
        })
    }

    return (
        <>
            <Navigation />
            <HomeBody />


            <Box padding={5} sx={{backgroundColor : "white", display: { xs: 'none', md: 'flex' }}}>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        {
                            Gpost.map(post =>
                                <div key={post._d} style={{marginBottom : "30px"}}>
                                    <Typography variant="h4" sx={{fontWeight : 500}} >
                                        {post.title}
                                    </Typography>
                                    <Typography variant="body1" sx={{fontWeight : 500}}>
                                        <b> {post.body}</b>
                                    </Typography>
                                </div>
                            )
                        }
                    </Grid>
                    <Grid item xs={3}>
                        
                       <a href='https://youtube.com/channel/UC3TuaZCxrc0s9sfOvDCc6JA' target="_blank" rel="noreferrer" style={{textDecoration : 'none'}}>
                            <Button variant="contained" color="error" sx={{fontWeight : 800, padding : 2, marginTop : 6}}>
                                    <YouTube sx={{marginRight : 1}} /> Goto YouTube
                                </Button>
                       </a>
                    </Grid>
                </Grid>
            </Box>

            <Box padding={5} sx={{backgroundColor : "white", display: { xs: 'flex', md: 'none' }}}>
            <Grid container alignItems='center' justify='center' alignContent='center'>
    <Grid item xs={12}>
        {
            Gpost.map(post =>
                <div key={post._d} style={{marginBottom : "10px"}}>
                    <Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" sx={{fontWeight : 500}} >
                                {post.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{fontWeight : 500}}>
                               <b> {post.body}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    </Grid>
    <Grid item xs={12} align='center'>
    <a href='https://youtube.com/channel/UC3TuaZCxrc0s9sfOvDCc6JA' target="_blank" rel="noreferrer" style={{textDecoration : 'none'}}>
                            <Button variant="contained" color="error" sx={{fontWeight : 800, padding : 2}}>
                                    <YouTube sx={{marginRight : 1}} /> Goto YouTube
                                </Button>
                       </a>
    </Grid>
</Grid>
            
                    
            
                      
            </Box>


           
            <HomePageEvents />
            <Footer />
        </>
    )
}

export default Home;