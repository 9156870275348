import React,{useState, useEffect} from "react";
import axios  from "axios";
import { Typography, Card, CardMedia,Grid } from "@mui/material";
import {Link} from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box'

const ShowEventsOnHomePage = () => {

    useEffect(() => {
        getAllEvents()
    })

    const [events, setEvents] = useState([]);

    const getAllEvents = async() => {
        await axios.get('api/users/getAllEventsHome').then(
            res => {
                setEvents(res.data)
            }
        ).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
         
            <Box sx={{display: { xs: 'none', md: 'flex' }}}>
            <Grid container spacing={1}>
                {
                    events.map(bevent =>
                        <Grid item  xs={3} key={bevent._id}>
                            <Link to="/Events" style={{textDecoration : 'none'}}>
                            <Card  sx={{ maxWidth: "100%", marginBottom : 1 }}>
                            <CardMedia component="img"  image={bevent.image} alt={bevent.title}/>          
                                <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                    {bevent.title}
                                </Typography>
                            </Card>
                            </Link>
                        </Grid>
                   )
                }
            </Grid>
            </Box>

            <Box sx={{display: { xs: 'flex', md: 'none' }}}>
            <Grid spacing={1}>
                {
                    events.map(bevent => 
                    
                        <Grid item  xs={12} key={bevent._id}>
                            <Link to="/Events" style={{textDecoration : 'none'}}>
                            <Card  sx={{ maxWidth: "100%", marginBottom : 1 }}>
                            <CardMedia component="img" height="400"  image={bevent.image} alt={bevent.title}/>          
                                <Typography gutterBottom variant="h6" padding={1} sx={{marginBottom : 0}} component="div">
                                    {bevent.title}
                                </Typography>
                            </Card>
                            </Link>
                        </Grid>
                   )
                }
            </Grid>
            </Box>
 
        </>
    )
}

export default ShowEventsOnHomePage;